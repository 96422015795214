var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-9"},[_c('HnrButton',{attrs:{"text":'Thêm mới sách',"color":"primary"},on:{"hnrClickEvent":_vm.addBook}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.books},scopedSlots:_vm._u([{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":item.url,"width":"200px"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.getDetail(item.id)}}},[_vm._v(" mdi-lead-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [(item.sale)?_c('span',[_vm._v(" "+_vm._s(item.amount ? _vm.formatPrice(item.amount) : 0)+" đ ")]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{on:{"change":function($event){return _vm.changeStatus(item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPrice(item.price))+"đ ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }