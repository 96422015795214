import ApiService from "@/common/api.service";
//news store
// action types
export const GET_LIST_NEW = "/news/list";
export const SAVE_NEW = "/news/save";
export const UPDATE_NEW = "/news/edit";
export const DELETE_NEW = "/news/delete";
export const DELETE_IMAGE_STORAGE = "news/deleteFile";
export const UPLOAD_FLASHCARD_IMAGE = "/news/image/upload";
export const GET_DETAIL_NEWS = "/news/detail";

export const GET_LIST_CATEGORIES = "/news-categories/index";
export const SAVE_CATEGORIES = "/news-categories/create";
export const UPDATE_CATEGORIES = "/news-categories/update";
export const DELETE_CATEGORIES = "/news-categories/destroy";
export const GET_DETAIL_CATEGORIES = "/news-categories/edit";

export const SET_LIST_CATEGORIES = "setDetailCategories";
// mutation types
export const SET_DETAIL_CATEGORIES = "setListCategories";

export const SET_DETAIL_NEWS = "setDetailNew";
// mutation types
export const SET_LIST_NEW = "setListNew";

const state = {
  listNew: [],
  detailItem: {},
  listCategories: [],
  detailCategories: {}
};

// getters
const getters = {
  getListNew(state) {
    return state.listNew;
  },

  getDetailNew(state) {
    return state.detailItem;
  },

  getListCategories(state) {
    return state.listCategories;
  },

  getDetailCategories(state) {
    return state.detailCategories;
  }
};

// actions
const actions = {
  [GET_LIST_NEW](context, payload) {
    return new Promise(resolve => {
      ApiService.query(GET_LIST_NEW, payload).then(({ data }) => {
        if (data.status) {
          context.commit(SET_LIST_NEW, data);
        }
        resolve(data);
      });
    });
  },

  [GET_DETAIL_NEWS](context, payload) {
    return new Promise(resolve => {
      ApiService.post(GET_DETAIL_NEWS, payload).then(({ data }) => {
        if (data.status) {
          context.commit(SET_DETAIL_NEWS, data);
        }
        resolve(data);
      });
    });
  },

  [GET_LIST_CATEGORIES](context, payload) {
    return new Promise(resolve => {
      ApiService.query(GET_LIST_CATEGORIES, payload).then(({ data }) => {
        if (data.status) {
          context.commit(SET_LIST_CATEGORIES, data);
        }
        resolve(data);
      });
    });
  },

  [GET_DETAIL_CATEGORIES](context, payload) {
    return new Promise(resolve => {
      ApiService.get(GET_DETAIL_CATEGORIES, payload).then(({ data }) => {
        if (data.status) {
          context.commit(SET_DETAIL_CATEGORIES, data);
        }
        resolve(data);
      });
    });
  },

  [SAVE_CATEGORIES](context, payload) {
    return new Promise(resolve => {
      ApiService.post(SAVE_CATEGORIES, payload).then(({ data }) => {
        resolve(data);
      });
    });
  },

  [UPDATE_CATEGORIES](context, payload) {
    return new Promise(resolve => {
      ApiService.patch(UPDATE_CATEGORIES+ "/" + payload.id, payload).then(({ data }) => {
        resolve(data);
      });
    });
  },

  [DELETE_CATEGORIES](context, payload) {
    return new Promise(resolve => {
      ApiService.delete(DELETE_CATEGORIES, payload).then(({ data }) => {
        resolve(data);
      });
    });
  },

  [UPLOAD_FLASHCARD_IMAGE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(UPLOAD_FLASHCARD_IMAGE, payload)
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  [SAVE_NEW](context, payload) {
    return new Promise(resolve => {
      ApiService.post(SAVE_NEW, payload).then(({ data }) => {
        resolve(data);
      });
    });
  },

  [UPDATE_NEW](context, payload) {
    return new Promise(resolve => {
      ApiService.post(UPDATE_NEW, payload).then(({ data }) => {
        resolve(data);
      });
    });
  },

  [DELETE_NEW](context, payload) {
    return new Promise(resolve => {
      ApiService.post(DELETE_NEW, payload).then(({ data }) => {
        resolve(data);
      });
    });
  },

  [DELETE_IMAGE_STORAGE](context, payload) {
    return new Promise(resolve => {
      ApiService.post(DELETE_IMAGE_STORAGE, payload).then(({ data }) => {
        resolve(data);
      });
    });
  }
};

// mutations
const mutations = {
  [SET_DETAIL_NEWS](state, data) {
    state.detailItem = data.data;
  },

  [SET_LIST_NEW](state, data) {
    state.listNew = data.data;
  },

  [SET_DETAIL_CATEGORIES](state, data) {
    state.detailCategories = data.data;
  },

  [SET_LIST_CATEGORIES](state, data) {
    state.listCategories = data.data;
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};
