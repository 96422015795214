import ApiService from "@/common/api.service";

// action types
export const SAVE_CONF = "/update-setting-homepage";
export const GET_CONFIG = "/setting";
export const GET_HOME_PAGE_CONF = "/setting-homepage";

export const GET_TOP_SLIDER_CONF = "getCurrentSlide";
export const GET_COURSE_MENU_CONF = "getCurrentCourseMenu";
export const GET_TEACHER_CONF = "getCurrentTeacher";
export const GET_FEEDBACK_CONF = "getCurrentFeedback";

// mutation types
export const SET_HOME_PAGE_CONF = "setHomePageConf";
export const SET_CONFIG = "setConfig";
export const DELETE_SLIDE = "deleteSlide";
export const DELETE_COURSE = "deleteCourse";
export const DELETE_COURSE_HOT = "deleteCourseHot";
export const DELETE_FEEDBACK = "deleteFeedback";
export const DELETE_TEACHER = "deleteTeacher";
export const DELETE_CURRENT_TEACHER = "deleteCurrentTeacher";

const state = {
    fetchHomePageConf: {},
    homepageConf: {},
    currentTopSlider: {},
    currentCourseMenu: {},
    sliderHomepage: [],
    courseMenu: [],
    courseHot: [],
    feedbackConf: [],
    currentFeedback: {},
    bannerExam: {},
    setting: {},
    currentTeacher: {},
};
const getters = {
    homePageConf(state) {
        return state.fetchHomePageConf;
    },
    setting(state) {
        return state.setting;
    },
    topSliderConf(state) {
        return state.sliderHomepage;
    },
    courseMenu(state) {
        return state.courseMenu;
    },
    courseHot(state) {
        return state.courseHot;
    },
    feedbackConf(state) {
        return state.feedbackConf;
    },
    countdownConf(state) {
        return state.fetchHomePageConf ? state.fetchHomePageConf.countdown : {};
    },
    introTeacherConf(state) {
        return state.fetchHomePageConf ? state.fetchHomePageConf.teacher_intro : {};
    },
    benefitConf(state) {
        return state.fetchHomePageConf ? state.fetchHomePageConf.benefit : {};
    },
    footerConf(state) {
        return state.fetchHomePageConf ? state.fetchHomePageConf.footer : {};
    },
    bannerExam(state) {
        return state.fetchHomePageConf ? state.fetchHomePageConf.banner_exam : {};
    },
    currentTopSlider(state) {
        return state.currentTopSlider;
    },
    currentCourseMenu(state) {
        return state.currentCourseMenu;
    },
    currentFeedback(state) {
        return state.currentFeedback;
    },
    currentTeacher(state) {
        return state.currentTeacher;
    }
};

const actions = {
    [SAVE_CONF](state, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(SAVE_CONF, payload)
                .then(data => {
                    resolve(data);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    },
    [GET_HOME_PAGE_CONF](state) {
        return new Promise((resolve, reject) => {
            ApiService.get(GET_HOME_PAGE_CONF)
                .then(data => {
                    if (data.data.status) {
                        state.commit(SET_HOME_PAGE_CONF, data.data);
                    }
                    resolve(data);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    },
    [GET_CONFIG](state, playload) {
        return new Promise((resolve, reject) => {
            ApiService.query(GET_CONFIG, playload)
                .then(data => {
                    if (data.data.status) {
                        state.commit(SET_CONFIG, data.data.data);
                    }
                    resolve(data);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    },
};

const mutations = {
    [SET_HOME_PAGE_CONF](state, resp) {
        if (resp.data) {
            state.fetchHomePageConf = Object.assign({}, resp.data);
            state.homepageConf = Object.assign({}, resp.data);
            state.sliderHomepage = resp.data.slider_homepage;
            state.courseMenu = resp.data.course_menu;
            state.courseHot = resp.data.course_hot;
            state.feedbackConf = resp.data.feedback;
        } else {
            state.fetchHomePageConf = createEmptyConfig();
            state.homepageConf = createEmptyConfig();
        }
    },
    [SET_CONFIG](state, resp) {
        if (JSON.parse(resp.value))
            state.setting = JSON.parse(resp.value)
        else
            state.setting = JSON.parse(resp)
    },
    [DELETE_CURRENT_TEACHER](state) {
        state.currentTeacher = {}
    },
    [DELETE_SLIDE](state, item) {
        if (state.sliderHomepage.length == 1) {
            state.sliderHomepage = []
        } else {
            state.sliderHomepage.splice(item.id, 1);
        }

    },
    [DELETE_COURSE](state, item) {
        if (state.courseMenu.length == 1) {
            state.courseMenu = []
        } else {
            state.courseMenu = state.courseMenu.filter(e => e.id !== item.id)
        }
    },
    [DELETE_TEACHER](state, item) {
        if (item.key == 'listTeacherDetail') {
            if (state.setting.listTeacherDetail.length == 1) {
                state.setting.listTeacherDetail = []
            } else {
                state.setting.listTeacherDetail = state.setting.listTeacherDetail.filter(e => e.id !== item.id)
            }
        } else {
            if (state.setting.listTeacherList.length == 1) {
                state.setting.listTeacherList = []
            } else {
                state.setting.listTeacherList = state.setting.listTeacherList.filter(e => e.id !== item.id)
            }
        }

    },
    [DELETE_COURSE_HOT](state, item) {
        console.log(item,'DELETE_COURSE_HOT')
        if (state.courseHot.length == 1) {
            state.courseHot = []
        } else {
            state.courseHot = state.courseHot.filter(e => e.id !== item.id)
        }
    },
    [DELETE_FEEDBACK](state, item) {
        if (state.feedbackConf.length == 1) {
            state.feedbackConf = []
        } else {
            state.feedbackConf.splice(item.id, 1);
        }

    },
    [GET_TOP_SLIDER_CONF](state, payload) {
        var index = state.homepageConf.slider_homepage.findIndex(
            item => item.id == payload
        );
        state.currentTopSlider = {};
        if (index >= 0) {
            state.currentTopSlider = state.homepageConf.slider_homepage[index];
        }
    },
    [GET_COURSE_MENU_CONF](state, payload) {
        var index = state.homepageConf.course_menu.findIndex(
            item => item.id == payload
        );
        state.currentCourseMenu = {};
        if (index >= 0) {
            state.currentCourseMenu = state.homepageConf.course_menu[index];
        }
    },
    [GET_TEACHER_CONF](state, payload) {
        if (payload.key == 'listTeacherDetail') {
            let index = state.setting.listTeacherDetail.findIndex(
                item => item.id == payload.id
            );
            state.currentTeacher = {};
            if (index >= 0) {
                state.currentTeacher = state.setting.listTeacherDetail[index];
            }
        } else {
            let index = state.setting.listTeacherList.findIndex(
                item => item.id == payload.id
            );
            state.currentTeacher = {};
            if (index >= 0) {
                state.currentTeacher = state.setting.listTeacherList[index];
            }
        }
    },

    [GET_FEEDBACK_CONF](state, payload) {
        var index = state.homepageConf.feedback.findIndex(
            item => item.id == payload
        );
        state.currentFeedback = {};
        if (index >= 0) {
            state.currentFeedback = state.homepageConf.feedback[index];
        }
    },
};

function createEmptyConfig() {
    return {
        top_slider: [],
        intro_teacher: {
            title: "",
            image_src: null,
            content: ""
        },
        benefit: {
            type: 1,
            title: "",
            video_src: null,
            content: "",
            list: []
        },
        footer: {
            facebook: "",
            youtube: "",
            instagram: "",
            printerest: "",
            zalo: ""
        }
    };
}

export default {
    state,
    actions,
    mutations,
    getters
};
