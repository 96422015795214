<template>
    <div class="ma-5 order-detail">
        <h2>Chi tiết thông tin đơn hàng</h2>
        <h4 class="mt-4">1.Thông tin khách hàng</h4>
        <div>
            <span class="font-weight-bold">Họ và tên : </span><span>{{order.full_name}}</span>
        </div>
        <div>
            <span class="font-weight-bold">Email : </span><span>{{order.email}}</span>
        </div>
        <div>
            <span class="font-weight-bold">Số điện thoại : </span><span>{{order.phone_number}}</span>
        </div>
        <div>
            <span class="font-weight-bold">Địa chỉ : </span><span>{{order.address}}</span>
        </div>
      <v-divider></v-divider>
        <h4 class="mt-4"> 2.Thông tin đơn hàng</h4>
      <v-data-table
          :headers="headers"
          :items="order.order_detail"
          hide-default-footer
          class="elevation-1"
      >
        <template v-slot:item.title="{ item }">
          <div class="d-flex align-center" v-if="item.book">
             <span>
               <img :src="item.book.url" width="100px">
             </span>
            <span class="ml-2">{{ item.book.title }}</span>
          </div>
        </template>
        <template v-slot:item.number="{ item }">
          <div class="d-flex align-center justify-center">
            {{item.number}}
          </div>
        </template>
        <template v-slot:item.price="{ item }">
          <div class="text-center" v-if="!item.book.sale">{{ item.book ? formatPrice(item.book.price) : '' }}đ</div>
          <div class="text-center" v-else>{{ item.book ? formatPrice(item.book.amount) : '' }}đ</div>
        </template>
        <template v-slot:item.total="{ item }">
          <div class="text-center" v-if="!item.book.sale">
            {{ item.book ? formatPrice(parseFloat(item.book.price) * item.number) : '' }}đ</div>
          <div class="text-center" v-else>
            {{ item.book ? formatPrice(parseFloat(item.book.amount) * item.number) : '' }}đ</div>
        </template>
      </v-data-table>
      <v-divider></v-divider>
        <h4 class="mt-4">3.Chi tiết</h4>
        <div>
            <span class="font-weight-bold">Ngày mua : </span><span>{{order.register_date}}</span>
        </div>
        <div>
            <span class="font-weight-bold">Mã giảm giá: </span>
            <span v-if="order.discount_code_id && order.discount">{{order.discount.discount_code}}</span>
            <span v-else-if="order.affiliate_code_id && order.affiliate">{{order.affiliate.code_number}}</span>
            <span v-else>Không có mã giảm giá</span>
        </div>
      <div>
        <span class="font-weight-bold">Giá : </span><span>{{formatPrice(totalMoney)}} Đ</span>
      </div>
        <div>
            <span class="font-weight-bold">Số tiền phải trả: </span>
            <span v-if="order.discount_code_id && order.discount">
              {{getPrice(totalMoney,order.discount.amount_money,order.discount.type)}} Đ
            </span>
          <span v-else-if="order.affiliate_code_id && order.affiliate">
              {{getPrice(totalMoney,order.affiliate.amount_money,order.affiliate.type)}} Đ
            </span>
            <span v-else>{{totalMoney}}
              Đ</span>
        </div>
        <div>
            <span class="font-weight-bold">Hình thức thanh toán: </span>
            <span v-if="order.payment_category_id == 1">Chuyển khoản ngân hàng tại Việt Nam</span>
            <span v-if="order.payment_category_id == 2">Chuyển phát nhanh toàn quốc</span>
            <span v-if="order.payment_category_id == 3">Thanh toán qua MoMo</span>
        </div>

      <v-divider></v-divider>
      <h4 class="mt-4">4.Cập nhật trạng thái đơn hàng</h4>
        <validation-observer ref="send">
          <v-row>
            <v-col cols="6">
              <div>
                <span class="font-weight-bold mb-2">Trạng thái thanh toán: </span>
                <ValidationObserver
                    rules="required"
                    v-slot="{ errors }"
                >
                      <v-select
                          v-model="status"
                          :items="orders_status"
                          dense
                          outlined
                      ></v-select>
                      <span class="validate-error text-danger text-danger">
                              {{ errors[0] }}
                            </span>
                </ValidationObserver>
              </div>
            </v-col>
            <v-col cols="6">
                <span class="font-weight-bold mb-2">Ngày thanh toán: </span>
                    <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="date"
                            prepend-icon="mdi-calendar"
                            readonly
                            dense
                            outlined
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="date"
                          no-title
                          scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="menu = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.menu.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
            </v-col>
          </v-row>
                <div class="font-weight-bold mb-2">Ghi chú thanh toán:</div>
                    <v-textarea
                            outlined
                            dense
                            name="input-7-1"
                            v-model="note"
                    ></v-textarea>
        </validation-observer>
        <div>
            <v-row class="align-center justify-center">
                <v-col cols="9" md="3" xl="3" lg="3">
                    <HnrButton
                            :width="'100%'"
                            :color="'#000000'"
                            class="white--text text-center"
                            :text="'Lưu Trạng thái thanh toán'"
                            @hnrClickEvent="saveStatus"
                    />
                </v-col>
            </v-row>
        </div>
    </div>

</template>
<script>
    import {
      GET_ORDER_DETAIL_BOOK,
        UPDATE_STATUS_ORDER,
        UPDATE_CODE_ORDER,
        SEND_CODE_ORDER
    } from "@/store/orders.module";
    import {mapGetters} from "vuex";

    export default {
        data() {
            return {
                status: 1,
                date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                menu: false,
                note: '',
                code: '',
                orders_status: [
                    {
                        text: "Đang xử lý",
                        value: 1,
                    },
                    {
                        text: "Đã thanh toán",
                        value: 2,
                    },
                    {
                        text: "Thất bại",
                        value: 3,
                    },
                ],
              headers: [
                {
                  text: 'Sản phẩm',
                  sortable: false,
                  align: 'center',
                  value: 'title',
                  class: 'black--text text-center',
                },
                {text: 'Giá', value: 'price',align: 'center',class: 'black--text text-center',},
                {
                  text: 'Số lượng',
                  value: 'number',
                  width: '130px',
                  align: 'center',
                  class: 'black--text text-center',
                },
                {text: 'Tổng', value: 'total',align: 'center',class: 'black--text text-center',},
              ],
            }
        },
        components: {
            HnrButton: () => import( "@/components/elements/hnr-button"  )
        },
        computed: {
            ...mapGetters({
                order: "orderDetail",
            }),
          totalMoney() {
            let result = 0;
            this.order.order_detail.map(item => {
              if (item.book.sale){
                result = result + (parseFloat(item.book.amount) * parseFloat(item.number))
              }
              else {
                result = result + (parseFloat(item.book.price) * parseFloat(item.number))
              }
            })
            return result
          }
        },
        async created() {
            let order_id = this.$route.params.id
            if (order_id) {
                await this.$store.dispatch(GET_ORDER_DETAIL_BOOK, order_id);
            }
        },
        watch:{
            order(newVal){
                this.status = newVal.status
                if (newVal && newVal.date_payment){
                    this.date = newVal.date_payment
                    this.note = newVal.note
                }
                if (newVal && newVal.code){
                    this.code = newVal.code
                }
            }
        },
        methods: {
            formatNumber(num) {
                if (!num) return null
                return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            },
          formatPrice(price){
            let data_price = parseFloat(price)
            return new Intl.NumberFormat('de-DE').format(data_price)
          },
          getPrice(price_course,discount_price,type){
              if (!price_course|| !discount_price || !type) return 0
              if (type ==2){
                let price = parseFloat(price_course) - parseFloat(discount_price)
                return this.formatNumber(price)
              }
              if (type==1){
                let price = parseFloat(price_course) - (parseFloat(price_course) * parseFloat(discount_price)) / 100
                return this.formatNumber(price)
              }
          },
            async saveStatus() {
                let result = await this.$refs.send.validate()
                if (result) {
                    let payload = {
                        status: this.status,
                        date_payment: this.date,
                        note: this.note
                    }
                    let data  ={...this.order, ...payload }
                    await this.$store.dispatch(UPDATE_STATUS_ORDER, {
                            order: data,
                        }).then((data)=>{
                            if  (data.status){
                                this.$toasted.success('Lưu thành công', {
                                    position: "top-right",
                                    theme: "toasted-primary",
                                    duration : 1000
                                });
                            }
                            else{
                                this.$toasted.error('Lưu thất bại', {
                                    position: "top-right",
                                    theme: "toasted-primary",
                                    duration : 1000
                                });
                            }
                    })
                }

            },
            renderCode(){
                this.$store.dispatch(UPDATE_CODE_ORDER, {
                        order: this.order
                    }).then((data)=>{
                        if (data.status){
                            this.code = data.data.data
                            this.$toasted.success('Tạo mã code thành công', {
                                position: "top-right",
                                theme: "toasted-primary",
                                duration : 1000
                            });
                        }
                })
            },
            sendCode(){
                this.$store.dispatch(SEND_CODE_ORDER, {
                    order: this.order
                }).then((data)=>{
                    if (data.status){
                        this.$toasted.success('Gửi email cho User thành công', {
                            position: "top-right",
                            theme: "toasted-primary",
                            duration : 1000
                        });
                    }
                })
            }
        }
    }
</script>
<style lang="scss">
    .order-detail {
        font-size: 13pt;
        line-height: 200%;
    }
</style>
