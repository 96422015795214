<template>
  <v-container>
    <v-row>
      <v-tabs v-model="tab" background-color="transparent" grow>
        <v-tab v-for="item in items" :key="item" :to="item.link">
          {{ item.content }}
        </v-tab>
      </v-tabs>
      <hnr-button
        v-if="banButton"
        class="text-right mr-10"
        :text="lang.disbanned"
        :color="'red'"
        :rounded="true"
        :width="200"
        :outlined="true"
        @hnrClickEvent="bannedUser(user[0].id)"
      ></hnr-button>
      <hnr-button
        v-else
        class="text-right mr-10"
        :text="lang.banned"
        :color="'primary'"
        :rounded="true"
        :width="200"
        :outlined="true"
        @hnrClickEvent="bannedUser(user[0].id)"
      ></hnr-button>
    </v-row>
    <v-row class="text-center">
      <v-data-table
        :hide-default-footer="true"
        :headers="headers"
        :items="user"
        class="elevation-1 mx-auto mt-5 user-data-table-hover"
      >
        <template v-slot:item.created_at="{ item }">)
          {{ formatDate(item.created_at) }}
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>
import HnrButton from "@/components/elements/hnr-button";
import { GET_USER_INFO, BANNED_USER } from "@/store/user.module";

export default {
  name: "index",
  components: {
    HnrButton
  },

  data() {
    return {
      lang: {
        banned: "chặn người dùng",
        disbanned: "bỏ chặn người dùng"
      },
      banButton: true,
      tab: 0,
      test: parseInt(this.$route.params.id),
      items: [
        {
          content: "Chi tiết giáo viên",
          link: "/user/teacher/" + this.$route.params.id + "/info",
          path: "info"
        },
        {
          content: "Khóa giao tiếp 1-1",
          link: "/user/teacher/" + this.$route.params.id + "/OneOneCourse",
          path: "OneOneCourse"
        }
      ],
      headers: [
        {
          text: "Họ tên",
          align: "start",
          sortable: false,
          value: "full_name"
        },
        { text: "Email", value: "email" },
        { text: "Số điện thoại", value: "phone_number" },
        { text: "Trình độ", value: "level" },
        { text: "Ngày đăng ký", value: "created_at" }
      ],
      user_id: ""
    };
  },
  created() {
    let user_id = this.$route.params.id;
    this.user_id = this.$route.params.id;
    this.getUser(user_id);
  },

  computed: {
    user() {
      return [this.$store.getters.userInfo];
    },
    banUserActive() {
      return this.$store.getters.userInfo;
    }
  },
  watch: {
    banUserActive(val) {
      if (val.status === 0) {
        this.banButton = false;
      }
      if (val.status === 1) {
        this.banButton = true;
      }
    }
  },

  methods: {
    formatDate(date) {
      if (!date) return null
      let [dateFormat , ] = date.split(' ')
      const [year, month, day] = dateFormat.split('-');
      return `${day.slice(0,2)}/${month}/${year}`
    },
    getUser(user_id) {
      this.$store.dispatch(GET_USER_INFO, { id: user_id });
    },
    async bannedUser(user_id) {
      this.banButton = !this.banButton;
      let is_banned = null;
      if (this.banButton) {
        is_banned = 1;
      } else {
        is_banned = 0;
      }
      await this.$store.dispatch(BANNED_USER, {
        id: user_id,
        is_banned: is_banned
      });
    }
  }
};
</script>

<style lang="scss">
.user-data-table-hover {
  border-radius: 30px;
  width: 92%;
  padding: 0 48px;
  border: 1px solid black;
  .v-data-table-header {
    tr {
      th {
        span{
          color: black;
        }
        i {
          display: none;
        }
      }
    }
  }
  tbody {
    tr:hover {
      background-color: transparent !important;
    }
  }
}
</style>
