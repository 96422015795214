var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('div',{staticClass:"add-button-container"},[_c('v-btn',{ref:"addButton",attrs:{"color":"success"},on:{"click":function($event){_vm.dialog = true}}},[_vm._v("Thêm bài học ")])],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Thêm bài học")])]),_c('v-card-text',[_c('v-container',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{attrs:{"method":"post","id":"save-lesson-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveLesson)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Tên bài học"},model:{value:(_vm.lesson.title),callback:function ($$v) {_vm.$set(_vm.lesson, "title", $$v)},expression:"lesson.title"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.lessonsOption,"label":"Bài học cha"},model:{value:(_vm.lesson.reference_id),callback:function ($$v) {_vm.$set(_vm.lesson, "reference_id", $$v)},expression:"lesson.reference_id"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Từ khóa"},model:{value:(_vm.lesson.seo_title),callback:function ($$v) {_vm.$set(_vm.lesson, "seo_title", $$v)},expression:"lesson.seo_title"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Key mô tả"},model:{value:(_vm.lesson.seo_description),callback:function ($$v) {_vm.$set(_vm.lesson, "seo_description", $$v)},expression:"lesson.seo_description"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1)],1)]}}])})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticStyle:{"margin-left":"150px","position":"absolute","margin-bottom":"50px","margin-top":"20px"},attrs:{"width":"50","color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Hủy")]),_c('v-btn',{staticStyle:{"margin-left":"230px","position":"absolute","margin-bottom":"50px","margin-top":"20px"},attrs:{"width":"50","type":"submit","form":"save-lesson-form","color":"blue darken-1","text":""}},[_vm._v(" Lưu ")])],1)],1)],1),_c('draggable',{attrs:{"handle":".handle"},on:{"change":_vm.submitOrder},model:{value:(_vm.lessons),callback:function ($$v) {_vm.lessons=$$v},expression:"lessons"}},_vm._l((_vm.lessons),function(lesson,lessonIndex){return _c('curriculumItem',{key:lessonIndex,staticClass:"curriculum-parent item",attrs:{"lesson":lesson,"course_slug":_vm.course_slug},on:{"handleAction":_vm.handleAction}},[_c('draggable',{attrs:{"handle":".handle"},on:{"change":_vm.submitOrder},model:{value:(lesson.children),callback:function ($$v) {_vm.$set(lesson, "children", $$v)},expression:"lesson.children"}},[(lesson.open)?_vm._l((lesson.children),function(child,childIndex){return _c('curriculumItem',{key:childIndex,attrs:{"lesson":child,"course_slug":_vm.course_slug},on:{"handleChildAction":_vm.handleChildAction,"editTitle":_vm.editTitle}})}):_vm._e()],2)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }