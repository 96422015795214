var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',[_c('h4',[_vm._v("Tiêu đề câu hỏi")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('CkContent',{attrs:{"validateName":"question","content":_vm.question.content},on:{"getData":function (value) { return _vm.question.content = value; }}})],1)],1),_c('v-row',{staticClass:"pl-2"},[_c('v-col',{staticClass:"pa-5",attrs:{"cols":"12","sm":"12","md":"12"}},[(_vm.isSelectBox)?_c('v-col',{staticClass:"justify-center d-flex"},[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.question.question_type),callback:function ($$v) {_vm.$set(_vm.question, "question_type", $$v)},expression:"question.question_type"}},[_c('v-radio',{attrs:{"label":"Chọn đáp án là ảnh","value":1}}),_c('v-radio',{attrs:{"label":"Chọn đáp án là text","value":2}})],1)],1):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('h6',[_vm._v("Nhập đáp án")])]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"black"},on:{"click":_vm.addAnswer}},[_vm._v("Thêm đáp án ")])],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.question.question_type == 1)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1 text-center",attrs:{"headers":_vm.headers,"items":_vm.question.answers,"items-per-page":10},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}},{key:"item.check",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{model:{value:(item.check),callback:function ($$v) {_vm.$set(item, "check", $$v)},expression:"item.check"}})]}},{key:"item.file",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":item.url,"width":"100px"}}),_c('v-file-input',{staticClass:"pl-1",attrs:{"name":"image","prepend-icon":"mdi-camera","label":"Tải ảnh","accept":"image/png, image/jpeg, image/bmp, image/webp"},on:{"change":function($event){return _vm.onChangeFileUpload(item.id)},"click:clear":_vm.clearImage},model:{value:(item.file),callback:function ($$v) {_vm.$set(item, "file", $$v)},expression:"item.file"}})]}}],null,false,3592590909)})],1)],1):_vm._e(),(_vm.question.question_type == 2)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1 text-center",attrs:{"headers":_vm.headers,"items":_vm.question.answers,"items-per-page":10},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}},{key:"item.check",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{model:{value:(item.check),callback:function ($$v) {_vm.$set(item, "check", $$v)},expression:"item.check"}})]}},{key:"item.file",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"dense":""},model:{value:(item.content),callback:function ($$v) {_vm.$set(item, "content", $$v)},expression:"item.content"}})]}}],null,false,976080659)})],1)],1):_vm._e()],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-center justify-center"},[_c('div',{staticClass:"my-4"},[_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"blue-grey"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Hủy ")]),_c('v-btn',{attrs:{"color":"primary","type":"submit"},on:{"click":_vm.submitQuestion}},[_vm._v("Lưu ")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }