<template>
    <v-app>
        <v-container>
            <!-- material block -->
            <v-row>
                <div class="h5">I.Thêm tài liệu nghe</div>
            </v-row>
            <v-row class="align-center">
                <v-col cols="3">
                    <div class="subtitle-1">Thêm bài nghe</div>
                </v-col>
                <v-col cols="6">
                    <div v-if="material.audio">
                        <vuetify-audio
                                :file="material.audio.src"
                                color="success"
                                downloadable
                                autoPlay="false"
                        ></vuetify-audio>
                    </div>
                </v-col>
                <v-col cols="3">
                    <v-file-input
                            class="pl-1"
                            prepend-icon="mdi-soundcloud"
                            v-model="fileUpload"
                            accept=".mp3,audio/*"
                            label="Tải file nghe"

                            @change="onChangeFileUpload"
                    ></v-file-input>
                </v-col>
            </v-row>
            <v-row>
                <div class="h6">Thêm phụ đề</div>
            </v-row>
            <v-row>
                <v-col>
                    <CkContent
                            validateName="questionContent"
                            :content="material.subtitle"
                            @getData="getSubtitleContent"
                    ></CkContent>
                </v-col>
            </v-row>
            <v-row class="justify-center text-center"
            >
                <v-col>
                    <v-btn color="indigo" class="white--text" @click="submitMaterial"
                    >Cập nhật tài liệu
                    </v-btn>
                </v-col
                >
            </v-row>
            <!-- question block -->
            <v-row>
                <v-col cols="6">
                    <div class="h5">II.Câu hỏi</div>
                </v-col>
                <v-col cols="6" class="justify-end text-end">
                    <v-btn
                            rounded
                            color="#2B2B59"
                            class="white--text"
                            @click="addQuestion"
                    >Thêm câu hỏi mới
                    </v-btn
                    >
                </v-col>
            </v-row>
            <!-- check answer block -->
            <v-row>
                <v-col>
                    <div
                            v-for="question in questions"
                            :key="question.id"
                            class="question-group"
                    >
                        <v-row class="ma-20" no-gutters>
                            <v-col>
                                <div class="headline">
                                    Câu hỏi số {{ question.id }} :
                                    <div
                                            v-html="question.content"
                                    ></div>
                                </div>
                            </v-col>
                            <v-col class="justify-end text-end">
                                <v-btn @click="editQuestion(question)">
                                    <v-icon small class="mr-2">mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn @click="deleteQuestion(question)">
                                    <v-icon small>mdi-delete</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <!-- check answer  -->
                        <v-row>
                            <h6>Đáp án</h6>
                            <ul class="pl-10" id="example-1">
                                <li v-for="answer in question.answers" :key="answer.id">
                                    <div v-if="question.question_type == 1" class="my-3">
                                        <img :src="answer.url" width="100px"> <span
                                            v-if="answer.check==1 || answer.check == -1">(Đáp án đúng)</span>
                                    </div>
                                    <div v-if="question.question_type == 2">
                                        <div :class="[answer.check==1 || answer.check == -1 ? 'green--text' : '']">
                                            {{answer.content}}
                                        </div>
                                        <span v-if="answer.check==1 || answer.check == -1">(Đáp án đúng)</span>
                                    </div>

                                </li>
                            </ul>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
            <!-- fill answer block -->

        </v-container>
    </v-app>
</template>
<script>
    import {mapGetters} from "vuex";
    import CkContent from "@/components/ck-content";
    import {
        UPLOAD_EXERCISE_MATERIAL,
        GET_EXERCISE_CONTENT,
        UPDATE_EXERCISE_MATERIAL,
        DELETE_EXERCISE_CONTENT,
    } from "@/store/exercises.module";

    export default {
        components: {
            CkContent,
            VuetifyAudio: () => import("vuetify-audio"),
        },
        data() {
            return {
                subtitle: "",
                translated_subtitle: "",
                dialog1: false,
                dialog2: false,
                fileUpload: {},
                audio: {
                    src: "",
                },
                dialog: false,
            };
        },
        computed: {
            ...mapGetters({
                exercise: "exerciseDetail",
                material: "materialDetail",
                questions: "questionList",
            }),
        },
        methods: {
            addQuestion() {
                this.$router.push({
                    name: "exercise-listening-and-choose-answer-add",
                    query: {exercise_slug: this.exercise.slug},
                });
            },
            editQuestion(question) {
                this.$router.push({
                    path: "/exercise/listening-and-choose-answer/add",
                    query: {exercise_slug: this.exercise.slug, question_id: question.id},
                });
            },
            getSubtitleContent(value) {
                this.material.subtitle = value;
                this.material.translated_subtitle = value
            },
            onChangeFileUpload() {
                let formData = new FormData();
                formData.append("upload", this.fileUpload);
                this.$store
                    .dispatch(UPLOAD_EXERCISE_MATERIAL, formData)
                    .then((data) => {
                        if (data.data.status == 1) {
                            this.material.audio.src = data.data.data;
                            this.$forceUpdate();
                        }
                        else {
                            this.$toasted.error('File chưa đúng định dạng', {
                                position: "top-right",
                                duration: 3000
                            })
                        }
                    })
                    .catch((err) => {
                        console.log("err", err);
                    });
            },
            submitMaterial() {
                if (!this.material.audio.src) {
                    this.$toasted.error('Chưa nhập bài nghe', {
                        position: "top-right",
                        duration: 3000
                    })
                    return false
                }

                if (!this.material.subtitle) {
                    this.$toasted.error('Chưa nhập bản dịch', {
                        position: "top-right",
                        duration: 3000
                    })
                    return false
                }
                if (this.material.audio.src && this.material.subtitle) {
                    this.$store.dispatch(UPDATE_EXERCISE_MATERIAL, {
                        exercise: this.exercise,
                        audio: this.material.audio.src,
                        material: this.material,
                    }).then((data) => {
                        if (data.status) {
                            this.$toasted.success('Upload tài liệu thành công', {
                                position: "top-right",
                                duration: 3000
                            })
                        }
                    });
                }

            },
            deleteQuestion(item) {
                confirm("Are you sure you want to delete this item?") &&
                this.$store
                    .dispatch(DELETE_EXERCISE_CONTENT, {
                        question_id: item.id,
                        exercise: this.exercise,
                    })
                    .then(() => {
                        this.$store.dispatch(GET_EXERCISE_CONTENT, {
                            exercise_slug: this.exercise.slug,
                        });
                    });
            },
        },
    };
</script>
<style>
    .question-content img {
        width: 150px !important;
    }
</style>
