var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"test-form"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{attrs:{"method":"post","id":"check-login-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveData)}}},[_c('v-row',[_c('v-col',{staticClass:"h4 font-weight-black",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.language.user_full_name)+" "),_c('span',{staticClass:"red--text font-weight-normal"},[_vm._v("(*)")])]),_c('v-col',{attrs:{"cols":"10"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HnrInput',{attrs:{"text":_vm.detail.user_full_name},model:{value:(_vm.detail.user_full_name),callback:function ($$v) {_vm.$set(_vm.detail, "user_full_name", $$v)},expression:"detail.user_full_name"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"h4 font-weight-black",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.language.title)+" "),_c('span',{staticClass:"red--text font-weight-normal"},[_vm._v("(*)")])]),_c('v-col',{attrs:{"cols":"10"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HnrInput',{attrs:{"text":_vm.detail.title},model:{value:(_vm.detail.title),callback:function ($$v) {_vm.$set(_vm.detail, "title", $$v)},expression:"detail.title"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"h4 font-weight-black",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.language.slug)+" ")]),_c('v-col',{attrs:{"cols":"10"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HnrInput',{attrs:{"text":_vm.detail.slug},model:{value:(_vm.detail.slug),callback:function ($$v) {_vm.$set(_vm.detail, "slug", $$v)},expression:"detail.slug"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"h4 font-weight-black",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.language.content)+" "),_c('span',{staticClass:"red--text font-weight-normal"},[_vm._v("(*)")]),_c('div',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.language.picture)+" ")])]),_c('v-col',{attrs:{"cols":"10"}},[_c('CkContent',{attrs:{"content":_vm.detail.content},on:{"getData":_vm.getContent}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(_vm.contentErrorMessage)+" ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"h4 font-weight-black",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.language.categories)+" ")]),_c('v-col',{attrs:{"cols":"10"}},[(_vm.categoriesBtn)?_c('v-select',{attrs:{"items":_vm.categoriesList,"dense":"","item-text":"title","placeholder":_vm.detail.categories.title,"outlined":"","return-object":"","color":"primary"},model:{value:(_vm.categories),callback:function ($$v) {_vm.categories=$$v},expression:"categories"}}):_c('v-select',{staticClass:"shrink money-box",attrs:{"items":_vm.categoriesList,"dense":"","item-text":"title","outlined":"","return-object":"","color":"primary"},model:{value:(_vm.categories),callback:function ($$v) {_vm.categories=$$v},expression:"categories"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"h4 font-weight-black",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.language.seoTitle)+" "),_c('span',{staticClass:"red--text font-weight-normal"},[_vm._v("(*)")])]),_c('v-col',{attrs:{"cols":"10"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HnrInput',{attrs:{"text":_vm.detail.seo_title},model:{value:(_vm.detail.seo_title),callback:function ($$v) {_vm.$set(_vm.detail, "seo_title", $$v)},expression:"detail.seo_title"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"h4 font-weight-black",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.language.seoDescription)+" "),_c('span',{staticClass:"red--text font-weight-normal"},[_vm._v("(*)")])]),_c('v-col',{attrs:{"cols":"10"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"outlined":"","name":"input-7-4"},model:{value:(_vm.detail.seo_description),callback:function ($$v) {_vm.$set(_vm.detail, "seo_description", $$v)},expression:"detail.seo_description"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"h4 font-weight-black",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.language.seoKey)+" "),_c('span',{staticClass:"red--text font-weight-normal"},[_vm._v("(*)")])]),_c('v-col',{attrs:{"cols":"10"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HnrInput',{attrs:{"text":_vm.detail.seo_key},model:{value:(_vm.detail.seo_key),callback:function ($$v) {_vm.$set(_vm.detail, "seo_key", $$v)},expression:"detail.seo_key"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"h4 font-weight-black",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.language.image)+" "),_c('span',{staticClass:"red--text font-weight-normal"},[_vm._v("(*)")]),_c('div',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.language.imageDisplay)+" ")])]),_c('v-col',{attrs:{"cols":"10"}},[_c('v-file-input',{staticClass:"pl-1",attrs:{"prepend-icon":"mdi-camera","label":"Upload image"},on:{"change":_vm.onChangeFileUpload},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}}),(_vm.cardImage)?_c('v-img',{attrs:{"max-height":"350","max-width":"500","src":_vm.cardImage}}):_vm._e(),_c('span',{staticClass:"red--text text-h6"},[_vm._v(_vm._s(_vm.img_error_msg))])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12 text-center"}},[_c('span',{staticClass:"validate-error text-danger text-danger float-left"},[_vm._v(" (*) : Mục cần nhập ")]),_c('HnrButton',{attrs:{"text":'Lưu',"color":'primary',"rounded":true,"outlined":true,"width":400,"type":"submit","form":"check-login-form"}})],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }