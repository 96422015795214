export const HOME_CONF_TOP_BAR_LIST_TYPE = 1;
export const HOME_CONF_TOP_BAR_HTML_TYPE = 2;



export const EXERCISE = {
      FLASHCARD: 1,
      LISTENING: 4,
      LISTENING_AND_CHOOSE_ANSWER: 41,
      READING: 5,
      READING_II: 51,
      WRITING: 7,
      CHOOSE_ANSWER: 6,
      WORD_LINK_ONE: 31,
      WORD_LINK_MANY: 32,
      TRANSLATE_FILL_WORD:21,
      TRANSLATE_IMAGE_SENTENCE:22,
      TRANSLATE_IMAGE_TEXT:23,
      TRANSLATE_TEXT_TEXT:24,
      TRANSLATE_SENTENCE_SENTENCE:25,
      GRAMMAR:27,
  };
  export const EXAM ={
      LISTENING:1,
      READING:2
  }

export const GROUP_TYPE_ID ={
    GENERAL:1,
    CUSTOM:2
}
export const EXAM_CONFIG_ID = {
    topicI_listening: 1,
    topicI_reading: 2,
    topicII_listening: 3,
    topicII_reading: 4,
    topicII_writing: 5
};

export const EXAM_TYPE ={
    LISTENING:1,
    READING:2,
    WRITING:3
}

export const LESSON_MEDIA_TYPE ={
    VIDEO:1,
    TEXT_AND_IMAGE:2,
}

export const ENCRYPT_MEDIA_PROCESS = {
    STATUS_ERROR : 0,
    STATUS_START : 1,
    STATUS_REMOVE_OLD_FILE : 2,
    STATUS_ENCRYPTING : 3,
    STATUS_PUSH_TO_STORAGE : 4,
    STATUS_COMPLETE : 5,
    STATUS_UPLOADED_VIDEO : 6,
}

export default {
    HOME_CONF_TOP_BAR_LIST_TYPE,
    HOME_CONF_TOP_BAR_HTML_TYPE,
    EXERCISE,
    EXAM,
    GROUP_TYPE_ID,
    EXAM_CONFIG_ID,
    EXAM_TYPE,
    ENCRYPT_MEDIA_PROCESS,
    LESSON_MEDIA_TYPE
};
