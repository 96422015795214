<template>
  <v-app>
    <v-container class="grey lighten-5 p-5">
      <v-row>
        <div class="d-flex flex-row ma-2" v-for="(course,index) in courses" :key="index">
          <router-link :title="course.title"
                       :to="{
                        path: '/course-one-to-one/list-lesson',
                        query: { course_slug: course.slug}}">
            <courseBox :course="course"/>
          </router-link>
        </div>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import {mapState} from "vuex";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import courseBox from "@/components/course-model/course-box";
import {GET_LIST_COURSE} from "@/store/courses.module";

export default {
  components: {
    courseBox,
  },
  mounted() {
    // set the tab from previous
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Danh sách khóa học", titledad: 'Khóa học'}]);
    this.$store.dispatch(GET_LIST_COURSE);
  },
  computed: {
    ...mapState({
      courses: (state) => state.courses.courses,
    }),
  },
};
</script>

<style scoped>
.search-bar {
  margin: 0 5px !important;
}
</style>
