var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',[_vm._v("Mã giảm giá")]),_c('h3',[_vm._v("Chọn khóa học")]),_c('v-row',[_c('v-col',{attrs:{"cols":"11"}},[_c('v-autocomplete',{staticClass:"autoCompleteBox",attrs:{"items":_vm.courses,"outlined":"","dense":"","chips":"","small-chips":"","multiple":"","item-text":"title","item-value":"id"},model:{value:(_vm.courseInput),callback:function ($$v) {_vm.courseInput=$$v},expression:"courseInput"}})],1)],1),_c('v-row',_vm._l((_vm.courses_choose),function(item){return _c('v-col',{key:item.id,staticClass:"pa-0 pr-6 mb-5",attrs:{"cols":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"grey lighten-3",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2,"max-width":"100%","outlined":""}},[_c('v-list-item',[_c('v-list-item-content',{staticClass:"pa-0"},[_c('div',{staticClass:"overline"},[_c('div',{staticClass:"mt-1"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.formatNumber(item.price))+" ")]),_c('router-link',{staticClass:"text-decoration-underline blue--text cursor",attrs:{"to":{
                                path: '/course',
                                query: {
                                  course_slug: item.slug,
                                  viewType: 1
                                }
                              }}},[_vm._v(" link ")])],1)])],1)],1)]}}],null,true)})],1)}),1),_c('h3',[_vm._v("Chọn khóa học 1-1")]),_c('v-row',[_c('v-col',{attrs:{"cols":"11"}},[_c('v-autocomplete',{staticClass:"autoCompleteBox",attrs:{"items":_vm.combos,"outlined":"","dense":"","chips":"","small-chips":"","multiple":"","item-text":"title","item-value":"id"},model:{value:(_vm.comboInput),callback:function ($$v) {_vm.comboInput=$$v},expression:"comboInput"}})],1)],1),_c('v-row',_vm._l((_vm.combo_choose),function(item){return _c('v-col',{key:item.id,staticClass:"pa-0 pr-6 mb-5",attrs:{"cols":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var hover = ref.hover;
return [_c('v-card',{staticClass:"grey lighten-3",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2,"max-width":"100%","outlined":""}},[_c('v-list-item',[_c('v-list-item-content',{staticClass:"pa-0"},[_c('div',{staticClass:"overline"},[_c('div',{staticClass:"mt-1"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.formatNumber(item.price))+" ")]),_c('router-link',{staticClass:"text-decoration-underline blue--text cursor",attrs:{"to":{
                                path: '/combo-course/detail',
                                query: {
                                  slug: item.slug,
                                  id: item.id
                                }
                              }}},[_vm._v(" link ")])],1)])],1)],1)]}}],null,true)})],1)}),1),_c('ValidationObserver',{ref:"discount"},[_c('h3',[_vm._v("Cách thức giảm giá"),_c('span',{staticClass:"red--text mr-4"},[_vm._v("(*)")])]),_c('v-row',{staticClass:"ma-0 pa-0 mt-3"},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.paymentMethod,"dense":"","item-text":"label","outlined":"","color":"primary"},model:{value:(_vm.discount.type),callback:function ($$v) {_vm.$set(_vm.discount, "type", $$v)},expression:"discount.type"}})]}}])})],1),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"7"}},[_c('ValidationProvider',{attrs:{"name":"price","rules":"required|number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('vuetify-money',{staticClass:"money-box-input",attrs:{"label":'',"placeholder":'Nhập số tiền khuyến mại',"readonly":false,"outlined":true,"clearable":true,"valueWhenIsEmpty":'',"options":_vm.options,"dense":""},model:{value:(_vm.discount.amount_money),callback:function ($$v) {_vm.$set(_vm.discount, "amount_money", $$v)},expression:"discount.amount_money"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('h3',[_vm._v("Số lần sử dụng"),_c('span',{staticClass:"red--text mr-4"},[_vm._v("(*)")])]),_c('v-row',{staticClass:"ma-0 pa-0 mt-3"},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"11"}},[_c('ValidationProvider',{attrs:{"rules":"required|number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","outlined":""},model:{value:(_vm.discount.max_of_use),callback:function ($$v) {_vm.$set(_vm.discount, "max_of_use", $$v)},expression:"discount.max_of_use"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('h3',[_vm._v("Ngày bắt đầu"),_c('span',{staticClass:"red--text mr-4"},[_vm._v("(*)")])]),_c('v-row',{staticClass:"ma-0 pa-0 mt-3"},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"11"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.start_date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.start_date=$event},"update:return-value":function($event){_vm.start_date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
                              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"readonly":"","dense":"","outlined":""},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.start_date)}}},[_vm._v(" OK ")])],1)],1)],1)],1),_c('h3',[_vm._v("Ngày kết thúc"),_c('span',{staticClass:"red--text mr-4"},[_vm._v("(*)")])]),_c('v-row',{staticClass:"ma-0 pa-0 mt-3"},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"11"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"return-value":_vm.end_date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.end_date=$event},"update:return-value":function($event){_vm.end_date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
                              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"readonly":"","dense":"","outlined":""},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu1 = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu1.save(_vm.end_date)}}},[_vm._v(" OK ")])],1)],1)],1)],1),_c('h3',[_vm._v("Mô tả")]),_c('div',{staticClass:" mt-5"},[_c('hnr-textarea',{staticClass:"input-textArea",attrs:{"label":'Nhập mô tả'},model:{value:(_vm.discount.description),callback:function ($$v) {_vm.$set(_vm.discount, "description", $$v)},expression:"discount.description"}})],1)],1),_c('hnr-button',{attrs:{"type":"submit","form":"check-form","rounded":"","width":"200","text":"Tạo mã giới thiệu","color":"blue","white-text":"true"},on:{"hnrClickEvent":_vm.saveCode}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }