<template>
  <v-container class="pa-0">
    <v-row class="curriculum-item">
      <div  class="icon-head handle">
        <BaseIcon  size="20px" :src="iconHead.src" />
      </div>
      <div :style="'color:'+lesson.color" class="title">
        <span v-if="!lesson.is_edited">{{ lesson.title }} </span>
        <span v-if="lesson.is_edited"><input   @keypress.enter="saveTitle" class="title" v-model="lesson.title" type="text" /> </span>
        <BaseIcon v-if="lesson.is_edited" @click="saveTitle" size="20px" :src="iconCheck.src" />
        <BaseIcon v-if="lesson.is_edited" @click="cancelTitle" size="20px" :src="iconCancel.src" />
        <v-icon v-if="!lesson.is_edited" @click="toggleEdit($event)">mdi-lead-pencil</v-icon>
        <span class="mx-2 ">
          <input type="checkbox" v-model="lesson.is_free" :id="lesson.id" @change="changeFree(lesson)">
        <label :for="lesson.id" class="black--text"> free</label><br>
        </span>
      </div>
      <div v-if="lesson.encrypt_process" class="video-status">
        <span :class="getClassTextStatus(lesson.encrypt_process)">{{ getTextStatus(lesson.encrypt_process) }}</span>
      </div>

      <div v-if="lesson.reference_id === 0" class="icon-end">
        <v-icon v-for="(icon,index) in iconsParent" :key="index" @click="handleAction(icon.action)">{{ icon.src }}</v-icon>
      </div>
      <div v-else-if="lesson.reference_id !== 0" class="icon-end">
        <v-icon v-for="(icon,index) in iconsChild" :key="index" @click="handleChildAction(icon.action)">{{ icon.src }}</v-icon>
      </div>
      <v-icon @click="toggleItem">{{ iconStatus }}</v-icon>
    </v-row>
    <!--    add children to view -->
    <div class="mr-0 ml-4">
      <slot></slot>
    </div>
  </v-container>

</template>

<script>
import BaseIcon from "@/components/base/BaseIcon";
import {UPDATE_LESSON_TITLE,UPDATE_IS_FREE,GET_LIST_LESSON} from "@/store/lessons.module";
import {SUCCESS} from '@/store/alerts.module';
import { ENCRYPT_MEDIA_PROCESS } from "@/common/constants";

export default {
  props: {
    lesson: {
      deep: true
    },
    course_slug: {
      deep: true
    },
  },
  components:{
    BaseIcon
  },
  name: "curriculum-item",
  data: () => ({
    iconHead: {src:require('@/assets/bars_icon.png'), action: 1, tooltip: "Sửa"},
    iconCheck:{src:require('@/assets/icons/check_icon.png')},
    iconCancel:{src:require('@/assets/icons/muliply_icon.png')},
    iconsParent: [
      {src: "mdi-content-copy", action:2, tooltip :"Duplicates"},
      {src: "mdi-alarm", action:3,tooltip : "Frequently contacted"},
      {src: "mdi-file-move", action:4,tooltip : "Move"},

      {src: "mdi-delete", action: 5, tooltip: "Xóa"},
    ],
    iconsChild: [
      {src: "mdi-square-edit-outline", action: 1, tooltip: "Sửa"},

      {src: "mdi-content-copy", action:2, tooltip :"Duplicates"},
      {src: "mdi-alarm", action: 3,tooltip : "Frequently contacted"},
      {src: "mdi-file-move",action:4,tooltip : "Move"},

      {src: "mdi-delete", action: 5, tooltip: "Xóa"},

      {src: "mdi-eye", action: 6 , tooltip: "Xem demo"},
      {src: "mdi-export", action: 7 ,tooltip: "Duplicates"},

    ],
    ENCRYPT_MEDIA_PROCESS: ENCRYPT_MEDIA_PROCESS,
  }),
  computed: {
    iconStatus() {
      if (this.lesson.open === true) {
        return "mdi-chevron-right-circle"
      } else if ((this.lesson.open === false)) {
        return "mdi-chevron-down-circle"
      } else {
        return "";
      }
    }
  },
  methods: {
    test(){
      console.log("test")
    },
    toggleItem() {
      this.lesson.open = !this.lesson.open
    },
    toggleEdit(){
      this.lesson.is_edited = !this.lesson.is_edited
    },
    saveTitle(){
      this.$store
          .dispatch(UPDATE_LESSON_TITLE, {
            lesson: this.lesson,
            course_slug : this.course_slug
          })
          .then((data) => {
            if(data.status){
              this.$store.dispatch(SUCCESS, {
                type: "success",
                message: "Cập nhật tiêu đề thành công"
              }, {
                root: true,
              });
              this.toggleEdit()
            }
          });
    },
    cancelTitle(){
      this.toggleEdit()
    },
    handleAction(action) {
      let param = {
        action:action,
        item:this.lesson
      }
      this.$emit("handleAction", param);
    },
    handleChildAction(action) {
      let param = {
        action:action,
        item:this.lesson
      }
      this.$emit("handleChildAction", param);
    },
    getTextStatus(encryptProcess) {
      let status = encryptProcess.status
      let videoStatus = '';
      switch(status) {
        case ENCRYPT_MEDIA_PROCESS.STATUS_ERROR:
          videoStatus = 'MÃ HÓA VIDEO LỖI'
          break;
        case ENCRYPT_MEDIA_PROCESS.STATUS_START:
          videoStatus = 'BẮT ĐẦU MÃ HÓA VIDEO'
          break;
        case ENCRYPT_MEDIA_PROCESS.STATUS_REMOVE_OLD_FILE:
          videoStatus = 'ĐANG XÓA VIDEO CŨ TRÊN S3'
          break;
        case ENCRYPT_MEDIA_PROCESS.STATUS_ENCRYPTING:
          videoStatus = 'ĐANG MÃ HÓA VIDEO'
          break;
        case ENCRYPT_MEDIA_PROCESS.STATUS_PUSH_TO_STORAGE:
          videoStatus = 'ĐANG UPLOAD VIDEO TỚI S3'
          break;
        case ENCRYPT_MEDIA_PROCESS.STATUS_COMPLETE:
          videoStatus = 'MÃ HÓA VIDEO HOÀN THÀNH'
          break;
        case ENCRYPT_MEDIA_PROCESS.STATUS_UPLOADED_VIDEO:
          videoStatus = 'ĐÃ UPLOAD VIDEO'
          break;
        default:
          break;
      }
      return videoStatus;
    },
    getClassTextStatus(encryptProcess) {
      let status = encryptProcess.status
      let textClass = '';
      switch(status) {
        case ENCRYPT_MEDIA_PROCESS.STATUS_ERROR:
          textClass = 'red--text'
          break;
        case ENCRYPT_MEDIA_PROCESS.STATUS_START:
          textClass = 'blue--text'
          break;
        case ENCRYPT_MEDIA_PROCESS.STATUS_REMOVE_OLD_FILE:
          textClass = 'blue--text'
          break;
        case ENCRYPT_MEDIA_PROCESS.STATUS_ENCRYPTING:
          textClass = 'blue--text'
          break;
        case ENCRYPT_MEDIA_PROCESS.STATUS_PUSH_TO_STORAGE:
          textClass = 'blue--text'
          break;
        case ENCRYPT_MEDIA_PROCESS.STATUS_COMPLETE:
          textClass = 'green--text'
          break;
        case ENCRYPT_MEDIA_PROCESS.STATUS_UPLOADED_VIDEO:
          textClass = 'blue--text'
          break;
        default:
          break;
      }
      return textClass;
    },
    async changeFree(lesson){
      lesson.course_slug =  this.course_slug
      this.$store
          .dispatch(UPDATE_IS_FREE,lesson)
          .then((data) => {
            if(data.status){
              this.$store.dispatch(SUCCESS, {
                type: "success",
                message: "Cập nhật Trạng thái thành công"
              }, {
                root: true,
              });
            }
            if (lesson.reference_id ==0 && lesson.children.length > 0){
              this.$store.dispatch(GET_LIST_LESSON, {course_slug: this.course_slug});
              this.$forceUpdate()
            }
          });
    }
  }
}
</script>

<style lang="scss">
.icon-end button{
  margin-left: 10px;
}
.curriculum-item {
  display: flex;
  padding: 10px 2px;
  border: 1px solid #2e3448;
  margin: 2px;

  .title {
    flex: 1;
  }
  input.title {
    min-width: 400px;
    border: 1px solid black;
  }
  div + div {
    margin: 0 10px;
  }
}

.curriculum-item:hover {
  border-left: 3px solid purple;
}
</style>
