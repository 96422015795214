<template>
  <v-container>
    <v-row>
      <v-tabs v-model="tab" background-color="transparent" grow>
        <v-tab v-for="item in items" :key="item" :to="link_tab(item)">
          {{ item.content }}
        </v-tab>
      </v-tabs>
    </v-row>
    <v-row class="text-center">
      <v-data-table
          :hide-default-footer="true"
          :headers="headers"
          :items="desserts"
          class="elevation-1 mx-auto mt-5"
      >
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "OneOneCourse",
  data: () => {
    return {
      tab: 0,
      items: [
        {
          content: "Chi tiết học viên",
          link: "/user/student/",
          path: "info"
        },
        {
          content: "Các khóa đã học",
          link: "/user/student/",
          path: "course"
        },
        {
          content: "Khóa giao tiếp 1-1",
          link: "/user/student/",
          path: "OneOneCourse"
        },
        {
          content: "Các bài thi",
          link: "/user/student/",
          path: "takeExam"
        }
      ],
      headers: [
        {
          text: "Khóa học ",
          align: "start",
          sortable: false,
          value: "course"
        },
        {text: "Bài học", value: "lesson"},
        {text: "Thời gian", value: "date"},
        {text: "Số giờ học", value: "time"},
        {text: "Giáo viên", value: "teacher"},
        {text: "Trạng thái", value: "status"}
      ],
      desserts: [
        {
          course: "Tiếng Hàn toàn diện - Sơ cấp",
          lesson: "Free Talk",
          date: "14-04-2021 : 20h30",
          time: "30 phút",
          teacher: "Dương Hồng Yên",
          status: "Chưa học"
        },
        {
          course: "Tiếng Hàn toàn diện - Sơ cấp",
          lesson: "Free Talk",
          date: "14-04-2021 : 20h30",
          time: "30 phút",
          teacher: "Dương Hồng Yên",
          status: "Chưa học"
        },
        {
          course: "Giao tiếp 1-1",
          lesson: "Free Talk",
          date: "14-04-2021 : 20h30",
          time: "30 phút",
          teacher: "Dương Hồng Yên",
          status: "đã học"
        }
      ]
    };
  },
  methods: {
    link_tab(link) {
      return link.link + this.$route.params.id + "/" + link.path;
    },
  }
};
</script>

<style scoped>

.theme--light.v-data-table {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  width: 90% !important;
}
</style>
