<template>
  <v-container>
    <h1>Mã giảm giá</h1>
    <h3>Chọn khóa học</h3>
    <v-row>
      <v-col cols="11">
        <v-autocomplete
            class="autoCompleteBox"
            v-model="courseInput"
            :items="courses"
            outlined
            dense
            chips
            small-chips
            multiple
            item-text="title"
            item-value="id"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col
          cols="3"
          class="pa-0 pr-6 mb-5"
          v-for="item in courses_choose"
          :key="item.id"
      >
        <v-hover v-slot="{ hover }">
          <v-card
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
              max-width="100%"
              outlined
              class="grey lighten-3"
          >
            <v-list-item>
              <v-list-item-content class="pa-0">
                <div class="overline">
                  <div class="mt-1">
                    {{ item.title }}
                  </div>
                  <div>
                    {{ formatNumber(item.price) }}
                  </div>
                  <router-link
                      class="text-decoration-underline blue--text cursor"
                      :to="{
                                  path: '/course',
                                  query: {
                                    course_slug: item.slug,
                                    viewType: 1
                                  }
                                }"
                  >
                    link
                  </router-link>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <h3>Chọn khóa học 1-1</h3>
    <v-row>
      <v-col cols="11">
        <v-autocomplete
            class="autoCompleteBox"
            v-model="comboInput"
            :items="combos"
            outlined
            dense
            chips
            small-chips
            multiple
            item-text="title"
            item-value="id"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col
          cols="3"
          class="pa-0 pr-6 mb-5"
          v-for="item in combo_choose"
          :key="item.id"
      >
        <v-hover v-slot="{ hover }">
          <v-card
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
              max-width="100%"
              outlined
              class="grey lighten-3"
          >
            <v-list-item>
              <v-list-item-content class="pa-0">
                <div class="overline">
                  <div class="mt-1">
                    {{ item.title }}
                  </div>
                  <div>
                    {{ formatNumber(item.price) }}
                  </div>
                  <router-link
                      class="text-decoration-underline blue--text cursor"
                      :to="{
                                  path: '/combo-course/detail',
                                  query: {
                                    slug: item.slug,
                                    id: item.id
                                  }
                                }"
                  >
                    link
                  </router-link>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <ValidationObserver ref="discount">
      <h3>Cách thức giảm giá<span class="red--text mr-4">(*)</span></h3>
      <v-row class="ma-0 pa-0 mt-3">
        <v-col cols="4" class="ma-0 pa-0">
          <ValidationProvider
              rules="required"
              v-slot="{ errors }"
          >
            <v-select
                :items="paymentMethod"
                dense
                item-text="label"
                v-model="discount.type"
                outlined
                color="primary"
            ></v-select>
          </ValidationProvider>
        </v-col>
        <v-col cols="7" class="ma-0 pa-0">
          <ValidationProvider
              name="price" rules="required|number"
              v-slot="{ errors }"
          >
            <vuetify-money
                v-model="discount.amount_money"
                :label="''"
                :placeholder="'Nhập số tiền khuyến mại'"
                :readonly="false"
                :outlined="true"
                :clearable="true"
                :valueWhenIsEmpty="''"
                :options="options"
                class="money-box-input"
                dense
            />
            <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
        </v-col>
      </v-row>
      <h3>Số lần sử dụng<span class="red--text mr-4">(*)</span></h3>
      <v-row class="ma-0 pa-0 mt-3">
        <v-col cols="11" class="ma-0 pa-0">
          <ValidationProvider
              rules="required|number"
              v-slot="{ errors }"
          >
            <v-text-field
                dense
                v-model="discount.max_of_use"
                outlined
            ></v-text-field>
            <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
        </v-col>
      </v-row>
      <h3>Ngày bắt đầu<span class="red--text mr-4">(*)</span></h3>
      <v-row class="ma-0 pa-0 mt-3">
        <v-col cols="11" class="ma-0 pa-0">
          <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="start_date"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="start_date"
                  readonly
                  v-bind="attrs"
                  dense
                  outlined
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="start_date"
                no-title
                scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                  text
                  color="primary"
                  @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(start_date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <h3>Ngày kết thúc<span class="red--text mr-4">(*)</span></h3>
      <v-row class="ma-0 pa-0 mt-3">
        <v-col cols="11" class="ma-0 pa-0">
          <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              :return-value.sync="end_date"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="end_date"
                  readonly
                  v-bind="attrs"
                  dense
                  outlined
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="end_date"
                no-title
                scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                  text
                  color="primary"
                  @click="menu1 = false"
              >
                Cancel
              </v-btn>
              <v-btn
                  text
                  color="primary"
                  @click="$refs.menu1.save(end_date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <h3>Mô tả</h3>
      <div class=" mt-5">
        <hnr-textarea
            v-model="discount.description"
            :label="'Nhập mô tả'"
            class="input-textArea"/>
      </div>
    </ValidationObserver>
    <hnr-button
        type="submit"
        form="check-form"
        rounded
        width="200"
        text="Tạo mã giới thiệu"
        color="blue"
        @hnrClickEvent="saveCode"
        white-text="true"
    ></hnr-button>
  </v-container>
</template>
<script>
import {GET_LIST_COURSE} from "@/store/courses.module";
import {GET_LIST_COMBO} from "@/store/combo-course.module";
import {
  CREATE_DISCOUNT_CODE,
} from "@/store/discountCodes.module";
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      courseInput: [],
      courses_choose: [],
      comboInput: [],
      combo_choose: [],
      discount: {
        type: 1,
        description: '',
        number_of_used: 0
      },
      options: {
        locale: "pt-BR",
        prefix: "VNĐ$",
        suffix: "",
        length: 100,
        precision: 0,
      },
      paymentMethod: [
        {
          value: 2,
          label: "Tiền mặt(đ)"
        },
        {
          value: 1,
          label: "Phần trăm(%)"
        }
      ],
      start_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      end_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      menu1: false,
    }
  },
  mounted() {
    this.$store.dispatch(GET_LIST_COURSE);
    this.$store.dispatch(GET_LIST_COMBO);
  },
  computed: {
    ...mapGetters({
      courses: "listCourse",
      combos: "combos",
    })
  },
  watch: {
    courseInput(newval) {
      this.courses_choose = this.courses.filter(course => {
        if (newval.indexOf(course.id) != -1) {
          return course
        }
      })
    },
    comboInput(newval) {
      this.combo_choose = this.combos.filter(course => {
        if (newval.indexOf(course.id) != -1) {
          return course
        }
      })
    }
  },
  components: {
    HnrTextarea: () => import("@/components/elements/hnr-textarea"),
    HnrButton: () => import( "@/components/elements/hnr-button")
  },
  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    async saveCode() {
      let result = await this.$refs.discount.validate()
      if (result) {
        if (this.courseInput.length == 0 && this.comboInput.length == 0) {
          this.$toasted.error("Bạn chưa chọn khóa học để giảm giá !!",
              {
                position: 'top-right',
                duration: 3000
              })
          return false
        }
        if (this.discount.type == 1) {
          if (this.discount.amount_money == 0 || this.discount.amount_money > 100) {
            this.$toasted.error("Giá trị không hợp lệ!",
                {
                  position: 'top-right',
                  duration: 3000
                })
            return false
          }
        }
        let startDate = new Date(this.start_date);
        let endDate = new Date(this.end_date)
        if (endDate <= startDate) {
          this.$toasted.error("Ngày kết thúc không hợp lệ!!",
              {
                position: 'top-right',
                duration: 3000
              })
          return false
        }
        this.discount.start_time = this.start_date
        this.discount.end_time = this.end_date
        this.discount.course_id = this.courseInput
        this.discount.combo_id = this.comboInput
        let data = await this.$store.dispatch(CREATE_DISCOUNT_CODE, this.discount);
        if (data.status) {
          this.$router.push({
            path: "/promotion/list"
          });
        }
      }
    }
  }
}
</script>