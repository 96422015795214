<template>
  <v-container>
    <v-row class="ma-0 pa-0 justify-center align-center custom-btn">
      <v-col cols="6" :class="isinfor==1?'ma-0 pa-0 d-flex justify-end border-btn':'ma-0 pa-0 d-flex justify-end'">
        <v-btn
            dark
            width="100%"
            height="50"
            @click="onClick(1)"
        >
          Thông tin chung
        </v-btn>
      </v-col>
      <v-col cols="6" :class="isinfor==2?'ma-0 pa-0 d-flex border-btn':'ma-0 pa-0 d-flex'">
        <v-btn
            dark
            width="100%"
            height="50"
            @click="onClick(2)"
        >
          Danh sách người sử dụng mã
        </v-btn>
      </v-col>
    </v-row>
    <div v-show="isinfor==1">
      <v-row class="justify-center">
        <v-col cols="12" class="mb-0 pb-0 justify-center d-flex">
          <v-row class="pa-0 ma-0 justify-center">
            <v-col cols="6" class="mt-4 justify-end d-flex">
              <div class="font-weight-bold text-lg-h5 justify-end">Mã giới thiệu: {{ $route.query.affiliate_code }}
              </div>
            </v-col>
            <v-col cols="6">
              <hnr-button
                  :width="'200px'"
                  rounded
                  :color="'#000000'"
                  :text="lang.edit_affiliate"
                  @hnrClickEvent="editAffiliateCode()"
              >
              </hnr-button>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="mt-0 pt-0">
          <v-row class="pa-0 ma-0 justify-center">
            <v-col cols="6" class="mt-4 justify-end d-flex">
              <div class="font-weight-bold text-lg-h5">Link: <i class="green--text font-weight-normal">https://nori.eg4life.com</i>
              </div>
            </v-col>
            <v-col cols="6">
              <hnr-button
                  :width="'200px'"
                  rounded
                  :color="'#000000'"
                  :text="' Sao chép link'"
                  @hnrClickEvent="copyAffiliateCode($route.query.affiliate_code)"
              >
              </hnr-button>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <hr>
      <v-row class="justify-center align-center">
        <v-col cols="4" v-if="course">
          <div class="text-center">
            <h2 class="mt-5  mb-5">Khóa học</h2>
            <h5>{{ lang.amount_user }} : {{ course ? course.number_of_use : '' }}</h5>
            <h5>{{ lang.amount_money }} :
              {{ course ? formatNumber(course.amount_money) : '' }}{{ course && course.type == 1 ? '%' : 'đ' }}</h5>
          </div>
        </v-col>
        <v-col cols="4" v-if="book">
          <div class="text-center">
            <h2 class="mt-5 mb-5">Sách</h2>
            <h5>{{ lang.amount_user }} : {{ book ? book.number_of_use : '' }}</h5>
            <h5>{{ lang.amount_money }} : {{
                book ? formatNumber(book.amount_money) : ''
              }}{{ book && book.type == 1 ? '%' : 'đ' }}</h5>
          </div>
        </v-col>
        <v-col cols="4" v-if="combo" >
          <div class="text-center">
            <h2 class="mt-5  mb-5">Khóa học 1-1</h2>
            <h5>{{ lang.amount_user }} : {{ combo ? combo.number_of_use : '' }}</h5>
            <h5>{{ lang.amount_money }} :
              {{ combo ? formatNumber(combo.amount_money) : '' }}{{ combo && combo.type == 1 ? '%' : 'đ' }}</h5>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-show="isinfor == 2">
      <v-row v-if="user_used_codes && user_used_codes.length>0">
        <v-col cols="12">
          <v-data-table
              :headers="headers"
              :items="user_used_codes"
              :items-per-page="5"
              class="elevation-1"
          >
            <template v-slot:item.affiliate_code_type="{ item }">
              {{ formatCategoryText(item.affiliate_code_type) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import {GET_LIST_AFFILIATE_CODE} from "@/store/user.module";

export default {
  name: "list",
  data: () => {
    return {
      isinfor: 1,
      user_used_codes: [],
      courses: [],
      books: [],
      course: {},
      book: {},
      combo: {},
      lang: {
        code_number: "Mã khuyễn mãi",
        amount_user: "Số người đã sử dụng",
        name: "tên",
        affiliate_code: "Mã giảm giá",
        phone: "Số điện thoại",
        edit_affiliate: "Chỉnh sửa mã giới thiệu",
        amount_money: "Số tiền giảm giá",
      },
      headers: [
        {text: "Tên", value: "full_name"},
        {text: "Số điện thoại", value: "phone_number"},
        {text: "Email", value: "email"},
        {text: "Loại", value: "affiliate_code_type"},
        {text: "Ngày sử dụng", value: "used_date"}
      ]
    };
  },
  components: {
    HnrButton: () => import( "@/components/elements/hnr-button"  )
  },
  watch: {
    listCodeInfo(newVal) {
      if (newVal.user_used_codes.length > 0) {
        this.user_used_codes = newVal.user_used_codes;
      }
      if (newVal.course) {
        this.course = newVal.course
      }
      if (newVal.book) {
        this.book = newVal.book
      }
      if (newVal.combo) {
        this.combo = newVal.combo
      }
    }
  },
  computed: {
    listCodeInfo() {
      return this.$store.getters.listCodeUsed;
    }
  },
  created() {
    let affiliate_code = this.$route.query.affiliate_code;
    this.getList(affiliate_code);
  },
  methods: {
    onClick(active) {
      this.isinfor = active;
    },
    formatCategoryText(item) {
      if (item == 1) {
        return 'Khóa học';
      }
      if (item == 2) {
        return 'Sách';
      }
    },
    formatNumber(num) {
      if (!num) return ''
      let data_price = parseFloat(num)
      return new Intl.NumberFormat('de-DE').format(data_price)
    },
    getList(affiliate_code) {
      this.$store.dispatch(GET_LIST_AFFILIATE_CODE, {affiliate_code: affiliate_code});
    },
    editAffiliateCode() {
      this.$router.push({
        path: "/afflificate/edit",
        query: {
          affiliate_code: this.$route.query.affiliate_code,
        }
      });
    },
    copyAffiliateCode(text) {
      console.log('abc')
      let input = document.createElement('input') // tạo thẻ input giả
      document.body.appendChild(input) // gán thẻ đó vào bất kì đâu (sao cho không bị ảnh hướng layout nào là được)
      input.value = "https://nori.eg4life.com?ref=" + text; // gán giá trị vào input
      input.select()  // focus vào input
      document.execCommand('copy') // dùng lệnh này để copy text từ input
      input.remove()
      alert("Bạn đã sao chép link giới thiệu!\n\nHãy gửi link này đến học viên của bạn");
      //BASE_URL=https://nori.eg4life.com/
    }
  }
};
</script>

<style lang="scss">
.border-btn {
  .v-btn {
    border-bottom: 10px solid #FFFFFF !important;
    border-radius: 0px !important;
  }
}

.v-btn--is-elevated {
  box-shadow: none !important;
}

.custom-btn {
  .v-btn {
    border-radius: 0px !important;
    box-shadow: none !important;
  }
}
</style>
