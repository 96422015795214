<template>
  <v-card class="course-box-1" color="#F1EFF1" light>
    <v-img :src="course.images.src" aspect-ratio="1.7" height="50%"></v-img>
    <v-card-subtitle class="text-center" primary-title>
      <div class="max-line">
        {{ course.title }}
      </div>
    </v-card-subtitle>
    <v-card-actions>
      <v-list-item>
        <v-list-item-content>
          <div>Doanh thu</div>
          <v-list-item-title>
            <span class="subheading font-weight-bold">3000000000</span>
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-content align="end" justify="center">
          <span class="subheading mr-2">Đăng ký</span>
          <span class="font-weight-bold">88</span>
        </v-list-item-content>
      </v-list-item>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["course"],
};
</script>

<style >
.course-box-1 {
  width: 260px;
  height: 230px;
  border: 1px solid #36a635 !important;
  border-radius: 30px !important;
  font-style: normal;
  font-size: 12px;
  line-height: 21px;
}
.max-line {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.theme--light.v-card .v-card__subtitle {
  color: black !important;
}
.v-card__subtitle {
  font-weight: bold !important;
  font-size: 1rem !important;
  padding: 12px !important;
}
</style>
