<template>
  <div class="pa-9">
    <h1>
      Cấu hình trang slide sách
    </h1>
    <ValidationObserver ref="add_question">
      <div>
        <h4>Tiêu đề</h4>
        <ValidationProvider
            :name="'title'"
            rules="required"
            v-slot="{ errors }"
        >
          <v-file-input
              class="pl-1"
              prepend-icon="mdi-camera"
              v-model="image"
              label="Tải ảnh"
              @change="onChangeFileUpload"
              @click:clear="clearImage"
          ></v-file-input>
          <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
        </ValidationProvider>

      </div>
    </ValidationObserver>
    <v-data-table
        v-if="setting && setting.length > 0"
        :headers="headers"
        :items="setting"
        class="elevation-1"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
      </template>
      <template v-slot:item.src="{ item }">
        <v-img :src="item.src" width="200px"></v-img>
      </template>
    </v-data-table>
    <v-row class="d-flex align-center justify-center my-9">
      <v-col cols="4">
        <HnrButton
            :text="save"
            color="primary"
            @hnrClickEvent="saveConfig"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {UPLOAD_IMAGE} from "@/store/media.module";
import {GET_CONFIG, SAVE_CONF} from "@/store/setting.module";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";

export default {
  computed: {
    ...mapGetters({
      settingState: "setting"
    })
  },
  components: {
    HnrButton: () => import("@/components/elements/hnr-button")
  },
  data() {
    return {
      image: {},
      save: 'Lưu',
      headers: [
        {text: "STT", value: "id"},
        {text: "Ảnh", value: "src"},
        {text: "Xóa", value: "actions", sortable: false}
      ],
      setting: [],
      update: false
    }
  },
  watch: {
    settingState(newVal) {
      if (newVal) {
        console.log(newVal, 'newVal')
        this.setting = newVal
      }
    }
  },
  mounted() {
    let key = {
      'key': 'slide_book'
    }
    this.$store.dispatch(GET_CONFIG, key);
  },
  created() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Cấu hình trang slide sách", titledad:"Cấu hình"}]);
  },
  methods: {
    onChangeFileUpload() {
      this.imageError = "";
      let formData = new FormData();
      formData.append("upload", this.image);
      this.$store.dispatch(UPLOAD_IMAGE, formData).then(data => {
        if (data.data.status) {
          let object = {
            id: this.getMaxKey() + 1,
            src: data.data.data
          }
          this.update = true
          this.setting.push(object)
        }
      });
    },
    clearImage() {
      this.show = false;
    },
    async deleteItem(item) {
      let data_delete = []
      if (this.setting.length == 1) {
        data_delete = []
      } else {
        data_delete = this.setting.filter(e => e.id !== item.id)
      }
      let data = {
        key: "slide_book",
        value: data_delete
      }
      if (confirm("Are you sure you want to delete this item?")) {
        await this.$store.dispatch(SAVE_CONF, data).then(data => {
          if (data.data.status) {
            this.$store.dispatch(GET_CONFIG, {
              'key': 'slide_book'
            });
          }
        })
      }
    },
    async saveConfig() {
      if (this.update) {
        let payload = {
          key: 'slide_book',
          value: this.setting
        }
        this.$store.dispatch(SAVE_CONF, payload).then(data => {
          if (data.status) {
            this.$toasted.success('Lưu thành công', {
              position: "top-right",
              theme: "toasted-primary",
              duration: 1000
            });
          }
        });
      } else {
        this.$toasted.error('Không thể upload !!!', {
          position: "top-right",
          duration: 3000
        })
      }

    },
    getMaxKey() {
      let max = 1
      if (this.setting.length == 0) {
        return 0
      }
      this.setting.map((item) => {
        if (item.id >= max) {
          max = item.id
        }
      })
      return max
    }
  }
}
</script>