<template>
  <div class="ma-9">
    <HnrButton
        :text="'Thêm mới sách'"
        color="primary"
        @hnrClickEvent="addBook"
    />
    <v-data-table
        :headers="headers"
        :items="books"
    >
      <template v-slot:item.url="{ item }">
        <v-img
            :src="item.url"
            width="200px"
        ></v-img>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="getDetail(item.id)">
          mdi-lead-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item.id)">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:item.amount="{ item }">
        <span v-if="item.sale">
           {{ item.amount ? formatPrice(item.amount) : 0 }} đ
        </span>
      </template>
      <template v-slot:item.status="{ item }">
        <v-switch
            v-model="item.status"
            @change="changeStatus(item)"
        ></v-switch>
      </template>
      <template v-slot:item.price="{ item }">
        {{ formatPrice(item.price) }}đ
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {
  GET_LIST_BOOK, DELETE_BOOK, UPDATE_STATUS_BOOK
} from "@/store/book.module";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";

export default {
  components: {
    HnrButton: () => import("@/components/elements/hnr-button")
  },
  //list news
  data() {
    return {
      headers: [
        {text: "Tên bài viết", value: "title"},
        {text: "Slug", value: "slug"},
        {text: "Ảnh", value: "url"},
        {text: "Giá", value: "price"},
        {text: "Giá tiền giảm", value: "amount"},
        {text: "Trạng thái sách", value: "status"},
        {text: "Hành động", value: "actions", sortable: false}
      ]
    };
  },

  async created() {
    await this.$store.dispatch(GET_LIST_BOOK);
    await this.$store.dispatch(SET_BREADCRUMB, [{title: "Sách", titledad: 'Sách'}]);
  },

  computed: {
    ...mapGetters({
      books: "books"
    })
  },
  methods: {
    addBook() {
      this.$router.push('/book/create')
    },
    getDetail(id) {
      this.$router.push(`/book/detail/${id}`)
    },
    async deleteItem(id) {
      if (confirm("Bạn muốn xóa sách?")) {
        await this.$store.dispatch(DELETE_BOOK, {id: id}).then(data => {
          if (data.data.status) {
            this.$toasted.success('Xóa thành công', {
              position: "top-right",
              theme: "toasted-primary",
              duration: 1000
            });
            this.$store.dispatch(GET_LIST_BOOK);
          }
        });
      }
    },
    formatPrice(price) {
      let data_price = parseFloat(price)
      return new Intl.NumberFormat('de-DE').format(data_price)
    },
    async changeStatus(item) {
      let data = await this.$store.dispatch(UPDATE_STATUS_BOOK, item);
      if (data.data.status){
        this.$toasted.success('cập nhật trạng thái thành công', {
          position: "top-right",
          theme: "toasted-primary",
          duration: 1000
        });
      }
    }
  }
};
</script>