<template>
    <v-app>
        <v-container>
            <v-data-table
                    :headers="headers"
                    :items="flashcards"
                    sort-by="calories"
                    class="elevation-1"
            >
                <template slot="headers" slot-scope="props">
                    <tr>
                        <th v-for="header in props.headers" :key="header.text">
                            <v-icon small>arrow_upward</v-icon>
                            <v-icon class="no-rotate" @click.stop small>filter_list</v-icon>
                            {{ header.text }}
                        </th>
                    </tr>
                </template>
                <template v-slot:top>
                    <v-toolbar flat color="white">
                        <v-toolbar-title><h5>Thẻ học từ vựng</h5></v-toolbar-title>
                        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
                        <v-spacer></v-spacer>
                        <v-btn
                                color="primary"
                                dark
                                class="mb-2"
                                @click="addNew"
                        >Từ vựng mới
                        </v-btn>
                    </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
                    <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
                </template>
                <template v-slot:item.audio="{ item }">
                    <audio v-if="item.audio.src" class="audio-block" ref="audio"
                           controls="controls">
                        <source :src="item.audio.src" type="audio/mpeg">
                    </audio>
                </template>
                <template v-slot:item.images="{ item }">
                    <img :src="item.images.src" alt width="80" height="120"/>
                </template>
                <!-- <template v-slot:no-data>
                  <v-btn color="primary" @click="initialize">Reset</v-btn>
                </template>-->
            </v-data-table>
            <v-dialog v-model="dialog" max-width="500px">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <ValidationObserver ref="new_flashcard" v-slot="{ handleSubmit }">
                                <v-form
                                        @submit.prevent="handleSubmit(handleUpload)"
                                        method="post"
                                        id="check-login-form"
                                >
                                    <v-row>
                                        <ValidationProvider
                                                rules="required|minmax:0,10"
                                                v-slot="{ errors }"
                                        >
                                            <v-col cols="12" sm="6" md="12">
                                                <v-text-field
                                                        v-model="editedItem.word"
                                                        label="Từ vựng"
                                                ></v-text-field>
                                            </v-col>
                                            <span
                                                    class="validate-error text-danger text-danger"
                                            >
                              {{ errors[0] }}
                            </span>
                                        </ValidationProvider>

                                        <ValidationProvider
                                                rules="required"
                                                v-slot="{ errors }"
                                        >
                                            <v-col cols="12" sm="6" md="12">
                                                <v-text-field
                                                        v-model="editedItem.example"
                                                        label="Ví dụ"
                                                ></v-text-field>
                                            </v-col>
                                            <span
                                                    class="validate-error text-danger text-danger"
                                            >
                              {{ errors[0] }}
                            </span>
                                        </ValidationProvider>

                                        <ValidationProvider
                                                rules="required"
                                                v-slot="{ errors }"
                                        >
                                            <v-col cols="12" sm="6" md="12">
                                                <v-text-field
                                                        v-model="editedItem.translate"
                                                        label="Dịch nghĩa"
                                                ></v-text-field>
                                            </v-col>
                                            <span
                                                    class="validate-error text-danger text-danger"
                                            >
                              {{ errors[0] }}
                            </span>
                                        </ValidationProvider>
                                        <v-col cols="12" sm="6" md="12">
                                            <v-text-field
                                                    v-model="editedItem.note"
                                                    label="Ghi chú"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-file-input
                                                    class="pl-1"
                                                    prepend-icon="mdi-camera"
                                                    accept="image/png, image/jpeg, image/bmp, image/webp"
                                                    v-model="image"
                                                    label="Tải ảnh lên"
                                                    @change="onChangeFileUpload"
                                                    @click:clear="clearImage"
                                            ></v-file-input>
                                            <!-- <span class="validate-error text-danger">{{ imageError }}</span> -->
                                        </v-col>
                                        <div id="preview" style="width: 200px">
                                            <v-img v-if="cardImg" :src="cardImg"/>
                                        </div>
                                        <v-col cols="12">
                                            <v-file-input
                                                    class="pl-1"
                                                    prepend-icon="mdi-file-music-outline"
                                                    accept=".mp3,audio/*"
                                                    v-model="audio"
                                                    label="Tải audio lên mp3"
                                                    @change="onChangeAudioUpload"
                                                    @click:clear="clearAudio"
                                            ></v-file-input>
                                            <div>
                                                <audio v-if="cardAudio" class="audio-block" ref="audio"
                                                       controls="controls">
                                                    <source :src="cardAudio" type="audio/mpeg">
                                                    Your browser does not support the audio element.
                                                </audio>
                                            </div>
                                            <!-- <span class="validate-error text-danger">{{ imageError }}</span> -->
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </ValidationObserver>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close"
                        >Đóng
                        </v-btn
                        >
                        <v-btn
                                type="submit"
                                form="check-login-form"
                                color="blue darken-1"
                                text
                        >Lưu
                        </v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
    </v-app>
</template>

<script>
    import {mapState, mapGetters} from "vuex";
    // import CkContent from "@/components/ck-content";
    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
    import {
        GET_LIST_FLASHCARD,
        SAVE_FLASHCARD,
        DELETE_FLASHCARD,
        UPDATE_FLASHCARD,
        UPLOAD_FLASHCARD_IMAGE,
        UPLOAD_FILE
    } from "@/store/flashcards.module";
    import {GET_EXERCISE_CONTENT} from "@/store/exercises.module";
    import {GET_LESSON_DETAIL} from "@/store/lessons.module";

    export default {
        components: {
            // CkContent,
        },
        async mounted() {
            await this.$store.dispatch(SET_BREADCRUMB, [{title: "Flashcard"}]);
            this.lesson_slug = this.$route.query.lesson_slug;
            await this.$store.dispatch(GET_LESSON_DETAIL, {
                lesson_slug: this.lesson_slug
            });
            await this.$store.dispatch(GET_LIST_FLASHCARD, {exercise_id: 1});
        },
        data: () => ({
            dialog: false,
            headers: [
                // { text: "STT", value: "id" },
                {text: "Từ vựng", value: "word"},
                {text: "Ví dụ", value: "example"},
                {text: "Dịch nghĩa", value: "translate"},
                {text: "Ghi chú", value: "note"},
                {text: "audio", value: "audio"},
                {text: "Hình ảnh", value: "images", sortable: false},
                {text: "Thao tác", value: "actions", sortable: false}
            ],
            lesson_slug: null,
            editedIndex: -1,
            cardImg: "",
            cardAudio: "",
            editedItem: {
                word: "",
                example: "",
                translate: "",
                note: "",
                cardImg: "",
                cardAudio: ""
            },
            defaultItem: {
                word: "",
                example: "",
                transalte: "",
                note: "",
                images: {}
            },
            image: {},
            audio: {},
            course_id: null,
            prevRoute: null
        }),
        computed: {
            ...mapState({
                flashcards: state => state.flashcards.flashcards,
                lesson: state => state.lessons.lesson
            }),
            ...mapGetters({
                flashcards: "flashcardList",
                exercise: "exerciseDetail"
            }),
            formTitle() {
                return this.editedIndex === -1 ? "Tạo thẻ mới" : "Sửa thẻ";
            },
            submitTypeName() {
                return this.editedIndex === -1 ? "Save" : "Update";
            }
        },

        watch: {
            dialog(val) {
                val || this.close();
            }
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.prevRoute = from;
            });
        },
        methods: {
            onChangeFileUpload() {
                let formData = new FormData();
                formData.append("upload", this.image);
                this.$store
                    .dispatch(UPLOAD_FLASHCARD_IMAGE, formData)
                    .then(data => {
                        if (data.data.status == 1) {
                            this.cardImg = data.data.data;
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    });
            },
            onChangeAudioUpload() {
                let formData = new FormData();
                formData.append("uploadFile", this.audio);
                this.$store
                    .dispatch(UPLOAD_FILE, formData)
                    .then(data => {
                        if (data.status) {
                            if (!this.editedItem.audio) {
                                this.editedItem.audio = {
                                    src: ""
                                }
                            }
                            this.editedItem.audio.src = data.data;
                            if (data.status == 1) {
                                this.cardAudio = data.data;
                            }
                        }
                    })
            },
            handleUpload() {
                if (!this.cardAudio) {
                    this.$toasted.error('Bạn chưa upload audio', {
                        position: "top-right",
                        duration: 3000
                    })
                    return false
                }
                // format course data
                this.editedItem.lesson_id = this.lesson.id;
                this.editedItem.cardImg = this.cardImg;
                this.editedItem.cardAudio = this.cardAudio;
                this.editedItem.exercise_id = this.exercise.id;
                // add new flashcard
                if (this.editedIndex === -1) {
                    this.$store
                        .dispatch(SAVE_FLASHCARD, {
                            flashcard: this.editedItem
                        })
                        .then(() => {
                            this.cardImg = "";
                            this.image = {};
                            this.$store.dispatch(GET_EXERCISE_CONTENT, {
                                exercise_slug: this.exercise.slug
                            });
                            this.close();
                        });
                }
                if (this.editedIndex !== -1) {
                    this.$store
                        .dispatch(UPDATE_FLASHCARD, {
                            flashcard: this.editedItem
                        })
                        .then(() => {
                            this.$store.dispatch(GET_EXERCISE_CONTENT, {
                                exercise_slug: this.exercise.slug
                            });
                            this.close();
                        });
                }
            },
            async editItem(item) {
                this.editedIndex = this.flashcards.indexOf(item);
                this.editedItem = await Object.assign({}, item);
                this.cardImg = this.editedItem.images.src;
                this.cardAudio = this.editedItem.audio.src;
                this.dialog = true;
            },
            addNew() {
                this.clearImage()
                this.clearAudio()
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
                this.dialog = true

            },
            deleteItem(item) {
                confirm("Are you sure you want to delete this item?") &&
                this.$store
                    .dispatch(DELETE_FLASHCARD, {
                        flashcard: item
                    })
                    .then(() => {
                        this.$store.dispatch(GET_EXERCISE_CONTENT, {
                            exercise_slug: this.exercise.slug
                        });
                    });
            },

            close() {
                this.dialog = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            clearImage() {
                this.cardImg = "";
                this.image = {};
            },
            clearAudio() {
                this.cardAudio = "";
                this.audio = {}
            },
            resetForm() {
                if (this.$refs.new_flashcard) {
                    this.$refs.new_flashcardnew_flashcard.reset();
                }
            },
            save() {
            }
        }
    };
</script>
<style>
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
        font-size: 0.87rem !important;
    }

    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
        font-size: 1rem !important;
    }
</style>
