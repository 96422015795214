<template>
  <v-container>
    <v-row>
      <v-tabs v-model="tab" background-color="transparent" grow>
        <v-tab v-for="item in items" :key="item" :to="link_tab(item)">
          {{ item.content }}
        </v-tab>
      </v-tabs>
    </v-row>
    <v-row class="text-center">
      <v-data-table
        :headers="headers"
        :items="lessonProcess"
        class="elevation-1 mx-auto mt-5"
      >
        <template v-slot:item.course.number_month_expired="{ item }">
          {{ item.course.number_month_expired }} tháng
        </template>
        <template v-slot:item.course.process.percent="{ item }">
          <v-progress-linear :value="item.course && item.course.process ? item.course.process.percent : 0" height="25">
            <strong>{{ Math.ceil(item.course && item.course.process ? item.course.process.percent : 0) }}%</strong>
          </v-progress-linear>
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>


import { GET_PURCHASE_COURSE_LIST } from "@/store/courses.module";
export default {

  data: () => {
    return {
      tab: 0,
      items: [
        {
          content: "Chi tiết học viên",
          link: "/user/student/",
          path: "info"
        },
        {
          content: "Các khóa đã học",
          link: "/user/student/",
          path: "course"
        },
        {
          content: "Khóa giao tiếp 1-1",
          link: "/user/student/",
          path: "OneOneCourse"
        },
        {
          content: "Các bài thi",
          link: "/user/student/",
          path: "takeExam"
        }
      ],
      headers: [
        {
          text: "Khóa học",
          align: "start",
          sortable: false,
          value: "course.title"
        },
        { text: "Thời gian đăng ký", value: "active_date" },
        { text: "Thời hạn học", value: "course.number_month_expired" },
        { text: "Hoàn thành", value: "course.process.percent" }
      ]
    };
  },
  computed: {
    lessonProcess() {
      return this.$store.getters.purchaseCourseList;
    }
  },
  created() {
    console.log(1212)
    let user_id = this.$route.params.id;
    console.log(user_id)
    this.getLessonProcess(user_id);
  },
  methods: {
    link_tab(link) {
      return link.link + this.$route.params.id + "/" + link.path;
    },
    getLessonProcess(user_id) {
      this.$store.dispatch(GET_PURCHASE_COURSE_LIST, { user_id: user_id });
    }
  }
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  width: 90% !important;
}

.v-application .primary {
  background-color: limegreen !important;
}
</style>
