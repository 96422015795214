<template>
  <v-app>
    <v-container class="grey lighten-5 p-5">
      <v-row>
        <v-select
          class="font-weight-black search-bar"
          :items="items"
          label="Lọc theo"
          dense
          solo
          width="50px"
        ></v-select>
        <v-text-field
          class="search-bar"
          prepend-inner-icon="mdi-search-web"
          @click:prepend-inner="action"
          placeholder="Nhập tên khóa học"
          outlined
          dense
        ></v-text-field>

        <v-select
          class="font-weight-black search-bar"
          :items="items"
          label="Sắp xếp theo doanh thu"
          solo
          dense
        ></v-select>
        <v-select class="font-weight-black search-bar" :items="items" label="Tăng dần" solo dense></v-select>
      </v-row>
      <!-- <v-row no-gutters>
        <v-flex class="pb-10 px-2">
          <v-card class="course-box-1 align-center d-flex">
            <v-card-text height="">
              <v-row class="justify-center text-center d-flex">
                <v-col cols="12">
                  <router-link to="/course/create">
                    <v-icon>mdi-plus</v-icon>
                    <div>Thêm khóa học</div>
                  </router-link>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-row>-->
      <v-row>
        <v-card class="course-box-1 align-center d-flex flex-row ma-2">
          <v-card-text height>
            <v-row class="justify-center text-center d-flex">
              <v-col cols="12">
                <router-link to="/course/create">
                  <v-icon>mdi-plus</v-icon>
                  <div>Thêm khóa học</div>
                </router-link>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <div  class="d-flex flex-row ma-2" v-for="(course,index) in courses" :key="index">
          <router-link :title="course.title" :to="{ path: '/course', query: { course_slug: course.slug,viewType:1 }}">
            <courseBox :course="course" />
          </router-link>
        </div>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import courseBox from "@/components/course-model/course-box";
import { GET_LIST_COURSE } from "@/store/courses.module";
export default {
  components: {
    courseBox,
  },
  data: () => ({
    items: [
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me 2" },
    ],
  }),
  mounted() {
    // set the tab from previous
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Danh sách khóa học",titledad:'Khóa học' }]);
    this.$store.dispatch(GET_LIST_COURSE);
  },
  computed: {
    ...mapState({
      courses: (state) => state.courses.courses,
    }),
  },
  methods: {
    action() {
      alert("hello");
    },
  },
};
</script>

<style scoped>
.search-bar {
  margin: 0 5px !important;
}
</style>
