<template>
  <div>
    <v-container>
      <ValidationObserver v-slot="{ handleSubmit }">
        <v-form
            @submit.prevent="handleSubmit(submit)"
            method="post"
            id="check-login-form"
        >
          <v-row>
            <v-col cols="6">
              <div class="font-weight-bold">Tiêu đề</div>
            </v-col>
            <v-col cols="6">
              <v-icon>mdi-edit</v-icon>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
              >
                <v-text-field
                    v-model="exercise.title"
                    label="Nhập tiêu đề"
                    outlined
                ></v-text-field>
                <span class="validate-error text-danger text-danger">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <div class="font-weight-bold"> Key tiêu đề</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <ValidationProvider
                      rules="required"
                      v-slot="{ errors }"
              >
                <v-text-field
                        outlined
                        label="Nhập key tiêu đề"
                        v-model="exercise.seo_title"
                ></v-text-field>
                <span class="validate-error text-danger text-danger">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <div class="font-weight-bold">Key mô tả</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <ValidationProvider
                      rules="required"
                      v-slot="{ errors }"
              >
                <v-text-field
                        outlined
                        label="Nhập key mô tả"
                        v-model="exercise.seo_description"
                ></v-text-field>
                <span class="validate-error text-danger text-danger">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-row>
                <v-col cols="6">
                  <div class="font-weight-bold">Dạng bài thưc hành</div>
                </v-col>
                <v-col cols="6">
                  <v-select
                      v-model="selected"
                      :items="items"
                      label="Chọn dạng bài"
                      dense
                      @change="getExerciseType"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <img :src="`./images/` + image"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center"
            >
              <v-btn class="white--text" color="black" type="submit"
                     depressed
                     form="check-login-form"
              >Tiếp tục
              </v-btn
              >
            </v-col
            >
          </v-row>
        </v-form>
      </ValidationObserver>
    </v-container>
  </div>
</template>

<script>
import {EXERCISE} from "@/common/constants";
import {SAVE_EXERCISE} from "@/store/exercises.module";

export default {
  data() {
    return {
      selected: {},
      exercise: {
        title: "",
        type: 0,
        lesson_id: 0,
      },
      image: "",
      items: [
        {
          text: "Flashcard",
          value: {
            value: EXERCISE.FLASHCARD,
            image: "flashcard.png",
          },
        },
        {
          text: "Đặt câu dựa vào tranh mẫu",
          value: {
            value: EXERCISE.TRANSLATE_IMAGE_SENTENCE,
            image: "imagesentence.png",
          },
        },
        {
          text: "Chuyển câu từ tiếng việt sang tiếng hàn",
          value: {
            value: EXERCISE.TRANSLATE_SENTENCE_SENTENCE,
            image: "sentence.png",
          },
        },
        {
          text: "Điền từ vào chỗ  trống",
          value: {
            value: EXERCISE.TRANSLATE_FILL_WORD,
            image: "fillword.png",
          },
        },
        {
          text: "Nhìn hình đoán từ",
          value: {
            value: EXERCISE.TRANSLATE_IMAGE_TEXT,
            image: "imagetext.png",
          },
        },
        {
          text: "Chuyển từ tiếng việt sang tiếng hàn",
          value: {
            value: EXERCISE.TRANSLATE_TEXT_TEXT,
            image: "text.png",
          },
        },
        {
          text: "Chọn từ không cùng nhóm",
          value: {
            value: EXERCISE.WORD_LINK_ONE,
            image: "onegroup.png",
          },
        },
        {
          text: "Ghép từ",
          value: {
            value: EXERCISE.WORD_LINK_MANY,
            image: "manygroup.png",
          },
        },
        {
          text: "Thực hành nghe",
          value: {
            value: EXERCISE.LISTENING,
            image: "listening.png",
          },
        },
        {
          text: "Thực hành đọc",
          value: {
            value: EXERCISE.READING,
            image: "reading.png",
          },
        },
        {
          text: "Thực hành ngữ pháp topik II",
          value: {
            value: EXERCISE.GRAMMAR,
            image: "fillword.png",
          },
        },
        {
          text: "Thực hành đọc hiểu topik II",
          value: {
            value: EXERCISE.READING_II,
            image: "fillword.png",
          },
        },{
          text: "Thực hành nghe hiểu topik II",
          value: {
            value: EXERCISE.LISTENING_AND_CHOOSE_ANSWER,
            image: "fillword.png",
          },
        },
        {
          text: "Thực hành viết topik II",
          value: {
            value: EXERCISE.WRITING,
            image: "reading.png",
          },
        },
        {
          text: "Chọn đáp án đúng",
          value: {
            value: EXERCISE.CHOOSE_ANSWER,
            image: "reading.png",
          },
        },
      ],
    };
  },
  mounted() {
    this.selected = this.items[0];
    this.exercise.type = this.selected.value.value;
    this.image = this.selected.value.image;
  },
  methods: {
    getExerciseType() {
      this.exercise.type = this.selected.value;
      // change image when select
      this.image = this.selected.image;
      // get correct format of selected
      this.selected=this.items.find(x => x.value.value === this.selected.value);
    },
    submit() {
      this.exercise.lesson_id = this.$route.query.lesson_id;
      this.$store
          .dispatch(SAVE_EXERCISE, {
            exercise: this.exercise,
          })
          .then((data) => {
            if (data.data.status === 1) {
              this.$router.push({
                path: "/exercise/detail",
                query: {lesson_slug: this.$route.query.lesson_slug},
              });
            }
          });
    },
  },
};
</script>
<style>
</style>
