<template>
    <v-app>
        <v-container>
            <v-row>
                <div class="h5">I.{{ titleContent }}</div>
            </v-row>
            <div class="body-1">1.{{ subTitleContent }}</div>
            <v-row class="ml-3">
                <CkContent
                        validateName="question"
                        :content="content"
                        @getData="getContent"
                        class="my-5 editor-content"
                ></CkContent>
            </v-row>
            <div class="body-1">2.{{ subTitleContentTranslate }}</div>
            <v-row class="ml-3">
                <CkContent
                        validateName="question"
                        :content="translateContent"
                        @getData="getContentTransalate"
                        class="my-5 editor-content"
                ></CkContent>
            </v-row>
            <v-row class="justify-center text-center">
                <v-col>
                    <v-btn color="indigo" class="white--text" @click="saveReading" width="200px"
                    >Cập nhật tài liệu
                    </v-btn>
                    <v-dialog
                            v-model="dialog"
                            width="500"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        </template>

                        <v-card>
                            <v-card-title class="headline lighten-2">
                                Cập nhập thành công
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-spacer></v-spacer>
                            <v-btn
                                    color="primary"
                                    text
                                    @click="dialog = false"
                            >
                                Close
                            </v-btn>
                            <v-card-text>
                            </v-card-text>
                            <v-card-actions>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col
                >
            </v-row>
            <v-row>
                <div class="h5">II.{{ titleQuestion }}</div>
            </v-row>
            <v-row class="align-center justify-center">
                <HnrButton
                        class="rounded-pill white--text"
                        :color="'#2B2B59'"
                        :text="addQuestionText"
                        @hnrClickEvent="addQuestion"
                        :width="'200px'"
                />
            </v-row>
            <!-- check answer block -->
            <v-row>
                <v-col>
                    <div
                            v-for="question in questions"
                            :key="question.id"
                            class="question-group"
                    >
                        <v-row class="ma-20" no-gutters>
                            <v-col>
                                <div class="headline">
                                    Câu hỏi số {{ question.id }} :<span
                                        v-html="question.content"
                                ></span>
                                </div>
                            </v-col>
                            <v-col class="justify-end text-end">
                                <v-btn @click="editQuestion(question)" width="100px">
                                    <v-icon small class="mr-2">mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn @click="deleteQuestion(question)" width="100px">
                                    <v-icon small>mdi-delete</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <!-- check answer  -->
                        <v-row>
                            <h4>Đáp án</h4>
                            <ul class="pl-10" id="example-1">
                                <li v-for="answer in question.answers" :key="answer.id">
                  <span v-if="answer.check == 1" class="green--text">{{
                      answer.content
                    }}</span>
                                    <span v-else>{{ answer.content }}</span>
                                </li>
                            </ul>
                            <!--              <v-divider></v-divider>-->
                        </v-row>
                    </div>
                </v-col>
            </v-row>
            <!-- fill answer block -->
        </v-container>
    </v-app>
</template>
<script>
    import CkContent from "@/components/ck-content";
    import HnrButton from "@/components/elements/hnr-button";
    import {
        SAVE_READING_MATERIAL,
        GET_READING_MATERIAL_DETAIL,
        GET_EXERCISE_CONTENT,
        DELETE_EXERCISE_CONTENT,
    } from "@/store/exercises.module";
    import {mapGetters} from "vuex";

    export default {
        data() {
            return {
                titleContent: 'Thêm tài liệu đọc',
                subTitleContent: 'Nhập đề đọc',
                subTitleContentTranslate: 'Nhập nội dung bài dịch',
                addQuestionText: 'Thêm câu hỏi mới',
                titleQuestion: 'Câu hỏi',
                content: '',
                translateContent: '',
                dialog: false
            }
        },
        components: {
            HnrButton,
            CkContent,
        },
        computed: {
            ...mapGetters({
                exercise: "exerciseDetail",
                questions: "questionList",
                readingMaterial: "readingMaterial",
                readResponse: "readResponse",
            }),
        },
        created() {
            if (this.exercise) {
                this.getReadingMaterial(this.exercise.id)
            }
        },
        watch: {
            readingMaterial(newVal) {
                this.content = newVal.content || ''
                this.translateContent = newVal.translated_content || ''
            },
            readResponse(val) {
                if (val.status === 1) {
                    this.$toasted.success('Update tài liệu thành công',
                        {
                            position: "top-right",
                            duration: 3000
                        })
                } else {
                    this.$toasted.error('Update tài liệu không thành công',
                        {
                            position: "top-right",
                            duration: 3000
                        })
                }
            }
        },
        methods: {
            getContent(value) {
                this.content = value
            },
            getContentTransalate(value) {
                this.translateContent = value
            },
            addQuestion() {
                this.$router.push({
                    name: "exercise-reading-add",
                    query: {exercise_slug: this.exercise.slug},
                });
            },
            editQuestion(question) {
                this.$router.push({
                    name: "exercise-reading-add",
                    query: {exercise_slug: this.exercise.slug, question_id: question.id},
                });
            },
            getReadingMaterial(exercise_id) {
                let payload = {
                    exercise_id: exercise_id
                }
                this.$store.dispatch(GET_READING_MATERIAL_DETAIL, payload)
            },
            async saveReading() {
                let readingMaterialId = this.readingMaterial && this.readingMaterial.id ? this.readingMaterial.id : null
                let data = {
                    id: readingMaterialId,
                    exercise_id: this.exercise.id,
                    content: this.content,
                    translated_content: this.translateContent,
                }
                await this.$store.dispatch(SAVE_READING_MATERIAL, data)
            },
            deleteQuestion(item) {
                confirm("Are you sure you want to delete this item?") &&
                this.$store
                    .dispatch(DELETE_EXERCISE_CONTENT, {
                        question_id: item.id,
                        exercise: this.exercise,
                    })
                    .then(() => {
                        this.$store.dispatch(GET_EXERCISE_CONTENT, {
                            exercise_slug: this.exercise.slug,
                        });
                    });
            },
        }
    }
</script>
<style lang="scss">
    .editor-content {
        width: 800px;
        padding-top: 10px;
    }

    @media screen and (max-width: 768px) {
        .editor-content {
            width: 700px;
        }
    }

    @media screen and (max-width: 480px) {
        .editor-content {
            width: 400px;
        }
    }

    @media screen and (max-width: 414px) {
        .editor-content {
            width: 350px;
        }
    }

    @media screen and (max-width: 375px) {
        .editor-content {
            width: 330px;
        }
    }

    @media screen and (max-width: 320px) {
        .editor-content {
            width: 280px;
        }
    }
</style>
