var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"exam-schedule-container"},[[(_vm.exam_schedules.length !== 0)?_c('v-row',{staticClass:"table-header"},[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"font-weight-black"},[_vm._v("Tên bài thi")])]),_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"font-weight-black"},[_vm._v("Thời gian thi")])]),_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"font-weight-black"},[_vm._v("Trạng thái")])]),_c('v-col',{attrs:{"cols":"3"}})],1):_vm._e(),(_vm.exam_schedules.length === 0)?_c('v-row',[_c('v-col',[_c('div',[_c('v-alert',{attrs:{"value":true,"color":"warning","icon":"priority_high","outlined":""}},[_vm._v(" Chưa có dữ liệu hiển thị ")])],1)])],1):_vm._e(),_vm._l((_vm.exam_schedules),function(item,index){return _c('v-row',{key:index,staticClass:"my-2",staticStyle:{"border":"1px solid black","border-left":"10px solid green"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('div',[_vm._v(_vm._s(item.title))])]),_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(item.start_date_time)+" ")]),_c('v-col',{attrs:{"cols":"2"}},[_c('div',{class:item.active_class},[_vm._v(_vm._s(item.active_text))])]),_c('v-col',{attrs:{"cols":"1"}},[_c('v-dialog',{attrs:{"retain-focus":false,"max-width":"1000px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"btn-submit",staticStyle:{"background-color":"black"},attrs:{"hidden":item.deleteActive,"elevation":"3","medium":"","outlined":"","raised":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("Sửa lịch ")])]}}],null,true),model:{value:(_vm.dialogSchedule),callback:function ($$v) {_vm.dialogSchedule=$$v},expression:"dialogSchedule"}},[_c('v-card',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}}),_c('v-col',{attrs:{"cols":"5"}},[_c('span',{staticClass:"title schel"},[_vm._v("ĐẶT LỊCH THI")])]),_c('v-col',{attrs:{"cols":"2"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}}),_c('v-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"name":"picker","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-date-picker',{model:{value:(_vm.picker),callback:function ($$v) {_vm.picker=$$v},expression:"picker"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-time-picker',{attrs:{"format":"ampm"},model:{value:(_vm.timer),callback:function ($$v) {_vm.timer=$$v},expression:"timer"}})],1),_c('v-col',{attrs:{"cols":"2"}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"validate-error text-danger text-center"},[_vm._v(_vm._s(_vm.message))])]),_c('v-col',{attrs:{"cols":"8"}}),_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"btn-cancel",attrs:{"large":""},on:{"click":function($event){_vm.dialogSchedule = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"btn-submit",staticStyle:{"background-color":"black","color":"white"},attrs:{"large":""},on:{"click":_vm.submitSchedule}},[_vm._v(" Save ")])],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-dialog',{attrs:{"retain-focus":false,"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"btn-submit",staticStyle:{"background-color":"black"},attrs:{"hidden":item.deleteActive,"elevation":"3","medium":"","outlined":"","raised":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("Xóa lịch ")])]}}],null,true),model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Bạn có chắc chắn muốn xóa lịch thi này? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Hủy")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.deleteItemConfirm()}}},[_vm._v("Xóa")]),_c('v-spacer')],1)],1)],1)],1)],1)}),(_vm.exam_schedules.length>0)?_c('v-row',[_c('div',{staticClass:"ml-10"},[_c('p',{staticClass:"red--text"},[_vm._v("Chú ý (*):")]),_c('p',{staticClass:"red--text"},[_c('i',[_vm._v("Khi bài thi đang diễn ra không thể chỉnh sửa hoặc xóa lịch thi!")])])])]):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }