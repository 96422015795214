var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-9"},[_c('h1',[_vm._v("Tạo sản phẩm đính kèm")]),_c('ValidationObserver',{ref:"add_deal"},[_c('h4',[_vm._v("Chọn khóa học")]),_c('ValidationProvider',{attrs:{"name":"course_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"outlined":"","dense":"","name":'course_id',"items":_vm.courses,"item-text":'title',"item-value":'id',"placeholder":'Nhập title sách'},model:{value:(_vm.deal.course_id),callback:function ($$v) {_vm.$set(_vm.deal, "course_id", $$v)},expression:"deal.course_id"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])}),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('h4',[_vm._v("Loại sản phẩm đính kèm")]),_c('ValidationProvider',{attrs:{"name":"course_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"outlined":"","dense":"","name":'type',"items":_vm.types,"item-text":'title',"item-value":'id',"placeholder":'Chọn loại sản phẩm'},on:{"change":_vm.changeType},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('h4',[_vm._v("Chọn sản phẩm")]),_c('ValidationProvider',{attrs:{"name":"course_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"outlined":"","dense":"","name":'reference_id',"items":_vm.references,"item-text":'title',"item-value":'id',"placeholder":'chọn sản phẩm'},on:{"change":_vm.changeProduct},model:{value:(_vm.reference_id),callback:function ($$v) {_vm.reference_id=$$v},expression:"reference_id"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('h4',[_vm._v("Số lượng")]),_c('ValidationProvider',{attrs:{"name":"number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","name":'title',"type":"number","placeholder":'Nhập số lượng'},model:{value:(_vm.number),callback:function ($$v) {_vm.number=$$v},expression:"number"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('h4',[_vm._v("Giá tổng sản phẩm đính kèm")]),_c('ValidationProvider',{attrs:{"name":"price","rules":"required|number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vuetify-money',{attrs:{"label":'',"placeholder":'Nhập giá tổng sản phẩm đính kèm',"readonly":false,"dense":true,"disabled":false,"outlined":true,"clearable":true,"valueWhenIsEmpty":'',"options":_vm.options},model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0] ? 'Giá tổng sản phẩm đính kèm không được để trống' : '')+" ")])]}}])})],1)],1)],1),_c('v-row',{staticClass:"d-flex align-center justify-center my-9"},[_c('v-col',{staticClass:"d-flex align-center justify-center text-center",attrs:{"cols":"5"}},[_c('HnrButton',{staticClass:"text-body-1",attrs:{"color":"primary","width":"200px","text":'Thêm sản phầm đi kèm'},on:{"hnrClickEvent":_vm.addProduct}})],1)],1),(_vm.products && _vm.products.length > 0)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.products},scopedSlots:_vm._u([{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":item.url,"width":"200px"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPrice(item.price))+"đ ")]}},{key:"item.price_root",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPrice(item.price_root))+"đ ")]}}],null,false,4121892428)}):_vm._e(),(_vm.products && _vm.products.length > 0)?_c('v-row',{staticClass:"d-flex align-center justify-center my-9"},[_c('v-col',{staticClass:"d-flex align-center justify-center text-center",attrs:{"cols":"5"}},[_c('HnrButton',{staticClass:"text-body-1",attrs:{"color":"primary","width":"100px","text":'Lưu'},on:{"hnrClickEvent":_vm.createDeal}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }