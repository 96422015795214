<template>
  <v-app>
    <v-container>
      <v-tabs
        v-model="active_tab"
        background-color=""
        class="elevation-2"
        dark
        :centered="true"
        :grow="true"
        :vertical="false"
        :right="false"
        @change="getChange"
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab :href="`#tab-1`"> Thông tin chung </v-tab>
        <v-tab :href="`#tab-2`"> Giáo trình </v-tab>
        <v-tab disabled :href="`#tab-3`"> Học viên </v-tab>
        <v-tab-item :value="'tab-' + 1">
          <courseInforEditor :course_slug="course_slug" />
        </v-tab-item>
        <v-tab-item :value="'tab-' + 2">
          <keep-alive>
          <courseCurriculumEditor :course_slug="course_slug" />
          </keep-alive>
        </v-tab-item>
        <v-tab-item :value="'tab-' + 3">
          <div>học viên</div>
        </v-tab-item>
      </v-tabs>
    </v-container>
  </v-app>
</template>
<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import courseInforEditor from "@/components/course-model/course-infor-editor";
import courseCurriculumEditor from "@/components/course-model/course-curriculum-editor";

export default {
  data() {
    return {
      active_tab: null,
      course_slug: "",
    };
  },
  components: {
    courseInforEditor,
    courseCurriculumEditor,
  },
  mounted() {
    this.course_slug = this.$route.query.course_slug;
    this.active_tab = "tab-" + this.$route.query.viewType;
    // set the tab from previousmounted() {
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    getChange(number) {
      this.$vuetify.goTo(0);
      if (number == "tab-1") {
        this.$store.dispatch(SET_BREADCRUMB, [{ title: "Thông tin khóa học" }]);
          localStorage.setItem(
          "course_slug",
          JSON.stringify(this.$route.query.course_slug)
        );
      }
      if (number == "tab-2") {
        localStorage.setItem("viewType", JSON.stringify(2));
        localStorage.setItem(
          "course_slug",
          JSON.stringify(this.$route.query.course_slug)
        );

        this.$store.dispatch(SET_BREADCRUMB, [{ title: "Giáo trình" }]);
      }
    },
  },
};
</script>
