<template>
    <v-app>
        <v-container>
            <!-- header -->
            <ValidationObserver ref="add_question">
                <v-row>
                    <v-col cols="3"><h4>Tiêu đề câu hỏi</h4></v-col>
                    <v-col cols="9">
                        <ValidationProvider
                                name="title"
                                rules="required"
                                v-slot="{ errors }"
                        >
                            <v-text-field
                                    v-model="question.content"
                                    label="Nhập từ"
                                    outlined
                            ></v-text-field>
                            <span class="validate-error text-danger text-danger">
                {{ errors[0] }}
              </span>
                        </ValidationProvider>
                    </v-col>
                </v-row>
            </ValidationObserver>
            <!-- main -->
            <!-- select answer block -->
            <v-row>
                <v-col cols="10">
                    <h6>Nhập đáp án</h6>
                </v-col>
                <v-col cols="2">
                    <v-btn color="black" class="white--text" @click="addAnswer"
                    >Thêm đáp án
                    </v-btn
                    >
                </v-col>

                <v-col cols="12">
                    <v-row>
                        <v-col cols="12">
                            <v-data-table
                                    :headers="headers"
                                    :items="question.answers"
                                    :items-per-page="10"
                                    class="elevation-1 text-center"
                            >
                                <template v-slot:item.actions="{ item }">
                                    <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
                                </template>
                                <template v-slot:item.check="{ item }">
                                    <v-checkbox v-model="item.check"></v-checkbox>
                                </template>
                                <template v-slot:item.content="{ item }">
                                    <v-text-field v-model="item.content"></v-text-field>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <!-- footer -->
            <v-row>
                <v-col class="text-center justify-center">
                    <div class="my-4">
                        <v-btn
                                class="ma-2 white--text"
                                color="blue-grey"
                                @click="$router.go(-1)"
                        >Hủy
                        </v-btn
                        >
                        <v-btn color="primary" type="submit" @click="submitQuestion"
                        >Lưu
                        </v-btn
                        >
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>
<script>
    import {
        SAVE_EXERCISE_CONTENT,
        GET_EXERCISE_DETAIL,
        GET_QUESTION_DETAIL,
        UPDATE_EXERCISE_CONTENT
    } from "@/store/exercises.module";
    import {mapGetters} from "vuex";

    export default {
        data: () => ({
            nameRules: [v => !!v || "Trường này không được bỏ trống"],
            newIndex: -1,
            headers: [
                {text: "Đáp án", value: "content"},
                {text: "Chọn đáp án đúng", value: "check"},
                {text: "Thao tác", value: "actions", sortable: false}
            ],
            cancelText: "hủy",
            saveText: "Lưu",
            writeAnswer: 0,
            isSelectBox: true,
            isUpdate: false,
            objQuestion: {}
        }),
        computed: {
            ...mapGetters({
                exercise: "exerciseDetail",
                question: "questionDetail"
            })
        },
        async created() {
            await this.$store.dispatch(GET_EXERCISE_DETAIL, {
                exercise_slug: this.$route.query.exercise_slug
            });
            await this.$store.dispatch(GET_QUESTION_DETAIL, {
                question_id: this.$route.query.question_id
            });
            if (this.$route.query.question_id) {
                this.isSelectBox = false;
                this.isUpdate = true;
            }
        },

        methods: {
            addAnswer() {
                this.objQuestion = {
                    id: --this.newIndex,
                    content: "",
                    check: 0
                };
                this.question.answers.push(this.objQuestion);
            },
            deleteItem(item) {
                const index = this.question.answers.indexOf(item);
                confirm("Bạn có chắc chắn muốn xóa không?") &&
                this.question.answers.splice(index, 1);
            },
            getMissingSentenceContent(value) {
                this.question.filled_dialog.missing_sentence = value;
            },
            getCompleteSentenceContent(value) {
                this.question.filled_dialog.complete_sentence = value;
            },
            async submitQuestion() {
                let result = await this.$refs.add_question.validate();
                if (result ) {
                    if (!this.question.content) {
                        this.$toasted.error('Bạn chưa nhập câu hỏi', {
                            position: "top-right",
                            duration: 3000
                        })
                        return false
                    }
                    if (this.question.answers.length == 0) {
                        this.$toasted.error('Bạn chưa nhập câu trả lười', {
                            position: "top-right",
                            duration: 3000
                        })
                        return false
                    }


                    let check = 0
                    this.question.answers.map(item => {
                        if (item.content) {
                            check++
                        }
                    })

                    if (check != this.question.answers.length) {
                        this.$toasted.error('Các câu trả lời chưa đầy đủ thông tin', {
                            position: "top-right",
                            duration: 3000
                        })
                        return false
                    }
                    if (this.countRightAnswer(this.question.answers) < 1) {
                        this.$toasted.error('Bạn chưa chọn câu trả lời đúng', {
                            position: "top-right",
                            duration: 3000
                        })
                        return false
                    }
                    this.question.type = this.writeAnswer;
                    // fill diaglog convert to array
                    let filled_dialog = [];
                    filled_dialog.push(this.question.filled_dialog);
                    if (this.isUpdate) {
                        this.$store
                            .dispatch(UPDATE_EXERCISE_CONTENT, {
                                question: this.question,
                                answers: this.question.answers,
                                filled_dialog: filled_dialog,
                                exercise: this.exercise
                            })
                            .then(data => {
                                if (data.data.status) {
                                    this.$router.go(-1);
                                }
                            });
                    }
                    if (!this.isUpdate) {
                        this.$store
                            .dispatch(SAVE_EXERCISE_CONTENT, {
                                question: this.question,
                                answers: this.question.answers,
                                filled_dialog: filled_dialog,
                                exercise: this.exercise
                            })
                            .then(data => {
                                if (data.data.status) {
                                    this.$router.go(-1);
                                }
                            });
                    }
                }
            },
            countRightAnswer(answers) {
                let count = 0;
                answers.forEach((item) => {
                    if (item.check) {
                        count++;
                    }
                })
                return count;
            },
        }
    };
</script>
