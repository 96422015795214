<template>
    <v-app>
        <div>
            <v-row>
                <v-col cols="6" class="justify-end text-end">
                    <v-btn
                            rounded
                            color="#2B2B59"
                            class="white--text"
                            @click="addQuestion"
                    >Thêm câu hỏi mới
                    </v-btn
                    >
                </v-col>
            </v-row>
            <div v-for="(question,index) in questions" :key="index" class="ml-3">
                <v-row>
                    <v-col col="9" class="d-flex font-weight-bold">
                        {{index + 1}}.
                        <div v-html="question.content"></div>
                    </v-col>
                    <v-col>
                        <v-icon small class="mr-2" @click="editQuestion(question)">mdi-pencil</v-icon>
                        <v-icon small @click="deleteQuestion(question)">mdi-delete</v-icon>
                    </v-col>

                </v-row>
                <div>Đáp án : <div v-html="question.answers[0].content"></div></div>
            </div>
        </div>

    </v-app>
</template>
<script>
    import {mapGetters} from "vuex";
    import {
        GET_EXERCISE_CONTENT,
        DELETE_EXERCISE_CONTENT,
    } from "@/store/exercises.module";

    export default {
        data() {
            return {
            };
        },
        computed: {
            ...mapGetters({
                exercise: "exerciseDetail",
                questions: "questionList",
            }),
        },
        methods: {
            addQuestion() {
                this.$router.push({
                    name: "exercise-writing-add",
                    query: {exercise_slug: this.exercise.slug},
                });
            },
            editQuestion(question) {
                this.$router.push({
                    path: "/exercise/writing/add",
                    query: {exercise_slug: this.exercise.slug, question_id: question.id},
                });
            },
            deleteQuestion(item) {
                confirm("Are you sure you want to delete this item?") &&
                this.$store
                    .dispatch(DELETE_EXERCISE_CONTENT, {
                        question_id: item.id,
                        exercise: this.exercise,
                    })
                    .then(() => {
                        this.$store.dispatch(GET_EXERCISE_CONTENT, {
                            exercise_slug: this.exercise.slug,
                        });
                    });
            },
        }
    };
</script>
<style>
    .question-content img {
        width: 150px !important;
    }
</style>
