<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <v-form
        @submit.prevent="handleSubmit(handleUpload)"
        method="post"
        id="check-login-form"
    >
      <v-row class="justify-end d-flex">
        <v-col cols="12" xl="6" lg="6">
          <v-checkbox v-model="course.active" class="mx-2" label="Hiện thị khóa học"></v-checkbox>
        </v-col>
        <v-col cols="12" xl="6" lg="6">
          <v-checkbox v-model="course.is_free" class="mx-2" label="Khóa học miễn phí"></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <div class="font-weight-black course-prop-label">Tên khóa học</div>
          <ValidationProvider name="name" rules="required" v-slot="{ errors }">
            <v-text-field
                v-model="course.title"
                placeholder="Nhập tên khóa học"
                dense
                outlined
            ></v-text-field>
            <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <div class="font-weight-black course-prop-label">Đường dẫn lading page</div>
            <v-text-field
                v-model="course.url_lading_page"
                placeholder="Nhập tên khóa học"
                dense
                outlined
            ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <div class="font-weight-black course-prop-label">Danh mục cha</div>
          <v-autocomplete v-model="course.category_id" :items="categories" outlined
                          item-text="title"
                          item-value="id"
                          dense
          >

          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <div class="font-weight-black course-prop-label">Thời gian học(tháng)</div>
          <ValidationProvider
              name="number_month_expired"
              rules="required|number"
              v-slot="{ errors }"
          >
            <v-text-field
                v-model="course.number_month_expired"
                placeholder="Nhập thời gian học"
                outlined
                dense
            ></v-text-field>
            <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <div class="font-weight-black course-prop-label">Giá khóa học</div>
          <ValidationProvider name="price" rules="required|number" v-slot="{ errors }">
            <vuetify-money
                v-model="course.price"
                v-bind:label="label"
                v-bind:placeholder="placeholder"
                v-bind:readonly="readonly"
                v-bind:disabled="disabled"
                v-bind:outlined="outlined"
                v-bind:clearable="clearable"
                v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
                v-bind:options="options"
            />
            <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <div class="font-weight-black course-prop-label">Từ khóa</div>
          <ValidationProvider name="meta" rules="required" v-slot="{ errors }">
            <v-text-field
                v-model="course.meta"
                placeholder="Nhập từ khóa"
                outlined
                dense
            ></v-text-field>
            <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <div class="font-weight-black course-prop-label">Mô tả ngắn gọn</div>
          <ValidationProvider
              name="description"
              rules="required|minmax:0,255"
              v-slot="{ errors }"
          >
            <v-textarea
                outlined
                v-model="course.description"
                value="Nhập mô tả ngắn gọn về khóa học"
            ></v-textarea>
            <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <h5 class="pl-3">Mô tả khóa học</h5>
          <div>
            <CkContent :content="course.introduction" @getData="getContent"></CkContent>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5" >
          <v-file-input
              class="pl-1"
              id="file_input"
              prepend-icon="mdi-camera"
              v-model="image"
              label="Tải ảnh máy tính (350px x 350px)"
              @change="onChangeFileUpload"
              @click:clear="clearImage"
          ></v-file-input>
          <div>
            <img v-if="course.images" :src="course.images.src"/>
          </div>
          <span class="validate-error text-danger">{{ imageError }}</span>
        </v-col>
        <v-col cols="4" >
          <v-file-input
              class="pl-1"
              id="file_input2"
              prepend-icon="mdi-camera"
              v-model="responsive_image"
              label="Tải ảnh cho điện thoại (380px x 220px)"
              @change="onChangeFileUpload"
              @click:clear="clearResponsiveImage"
          ></v-file-input>
          <div id="preview">
            <img v-if="course.responsive_image" :src="course.responsive_image.src"/>
          </div>
          <span class="validate-error text-danger">{{ responsiveImageError }}</span>
        </v-col>
      </v-row>
      <v-row class="pa-2 justify-center d-flex">
        <v-btn
            type="submit"
            v-if="!isLoading"
            width="150"
            depressed
            form="check-login-form"
            color="success"
        >Cập Nhật
        </v-btn
        >
        <v-btn
            v-if="isLoading"
            loading
            width="150"
            depressed
            form="check-login-form"
            color="success"
        >Cập Nhật
        </v-btn
        >
      </v-row>
    </v-form>
  </ValidationObserver>
</template>

<script>
// import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import {mapGetters, mapState} from "vuex";
import CkContent from "@/components/ck-content";
import {
  SAVE_COURSE,
  UPDATE_COURSE,
  UPLOAD_COURSE_IMAGE,
  GET_COURSE_DETAIL,
} from "@/store/courses.module";
import {
  GET_LIST_CATEGORY
} from "@/store/category.module";
import mixin from "@/store/mixin";

export default {
  mixins: [mixin],
  props: ["course_slug"],
  data: function () {
    return {
      value: "123456789",
      label: "",
      placeholder: "Nhập số tiền khóa học",
      readonly: false,
      disabled: false,
      outlined: true,
      clearable: true,
      valueWhenIsEmpty: "",
      options: {
        locale: "pt-BR",
        prefix: "VNĐ$",
        suffix: "",
        length: 11,
        precision: 0,
      },

      errors: {},
      url: "",
      image: null,
      responsive_image: null,
      imageError: "",
      responsiveImageError: "",
      isLoading: false,
      statusSelected: []
    };
  },
  components: {
    CkContent,
  },
  computed: {
    ...mapState({
      course: (state) => state.courses.detailCourse,
    }),
    ...mapGetters({
      categories: "categories"
    })
  },
  watch: {
    course: {
      immediate: true,
      handler() {
        this.statusSelected.push(this.course.status)
      }
    }
  },
  async mounted() {
    await this.$store.dispatch(GET_LIST_CATEGORY);
    if (this.course_slug != null) {
      this.$store.dispatch(GET_COURSE_DETAIL, {
        course_slug: this.course_slug,
      });
    }
    if (this.course_slug == null) {
      this.$store.dispatch(GET_COURSE_DETAIL);
    }
  },
  methods: {
    getContent(value) {
      this.course.introduction = value;
    },
    onChangeFileUpload() {
      if (this.image) {
        this.imageError = "";
        let formData = new FormData();
        formData.append("upload", this.image);
        this.$store
            .dispatch(UPLOAD_COURSE_IMAGE, formData)
            .then((data) => {
              if (data.data.status == 1) {
                this.url = data.data.data;
                this.course.images.src = this.url;
              }
            })
      }
      if (this.responsive_image) {
        console.log("reponsive image")
        this.responsiveImageError = "";
        let formData = new FormData();
        formData.append("upload", this.responsive_image);
        this.$store
            .dispatch(UPLOAD_COURSE_IMAGE, formData)
            .then((data) => {
              if (data.data.status == 1) {
                this.url = data.data.data;
                console.log(this.url, "url response")
                this.course.responsive_image.src = this.url;
              }
            })
      }
    },
    clearImage() {
      this.url = "";
      this.course.images.src = "";
    },
    clearResponsiveImage() {
      this.url = "";
      this.course.responsive_image.src = "";
    },

    handleUpload() {
      // add event loading

      this.isLoading = true;
      //update course
      if (this.course_slug != null) {
        this.$store
            .dispatch(UPDATE_COURSE, {
              course: this.course,
            })
            .then(() => {
              this.$router.push("/course/list");
            });
      }
      //save course
      if (this.course_slug == null) {
        let file_input = document.getElementById('file_input');
        let validator = this.validateImage(file_input);
        if (!validator.result) {
          this.imageError = validator.message;
          this.isLoading = false;
          return null;
        }

        let file_input2 = document.getElementById('file_input2');
        let validator2 = this.validateImage(file_input2);
        if (!validator2.result) {
          this.responsiveImageError = validator2.message;
          this.isLoading = false;
          return null;
        }

        this.$store
            .dispatch(SAVE_COURSE, {
              course: this.course,
            })
            .then(() => {
              this.isLoading = false;
              this.$router.push("/course/list");
            });
      }
    },
  },
};
</script>

<style>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px !important;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}

form {
  font-size: 14px;
}

.course-prop-label {
  margin-bottom: 5px;
}
</style>
